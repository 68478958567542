import React from 'react'
import { Link } from 'react-router-dom'
import { CloseIcon } from '../icons/icons'
export default function Modal({ open, children, wSize, hSize, title, close, path }) {
  return (
    <>
      {open && (
        <div className={['modal-bgframe', open ? 'show' : ''].join(' ')}>
          <div
            className="modal-frame"
            style={{
              width: `${wSize}%`,
              height: `${hSize}%`
            }}
          >
            <div className="title">
              <h1>{title}</h1>
              {path ? (
                <Link to={path}>
                  <CloseIcon size="30" />
                </Link>
              ) : (
                <CloseIcon size="30" onClick={close} />
              )}
            </div>
            {children}
          </div>
        </div>
      )}
    </>
  )
}
