import axios from 'axios'

export const login = async (content) => {
  const response = await axios.post('/auth/signin', content)
  return response.data.valid
}

export const getUser = async () => {
  try {
    const response = await axios.get('/auth/current/user')
    return response.data
  } catch (err) {
    console.log('getUser error', err)
    return { msg: err.response.data.message, loggedIn: false }
  }
}

export const logout = async (currentUser) => {
  await axios.get('/auth/logout')
  return false
}

export const checkPin = async (pin) => {
  try {
    const response = await axios.post('/auth/check/pin', { pin })
    return response.data.valid
  } catch (err) {
    console.log(err.response.data.msg)
    return err.response.data.valid
  }
}

export const forgotUsername = async (email) => {
  const response = await axios.post('auth/send/username', { email })
  return response.data
}
export const forgotPassword = async (email) => {
  const response = await axios.post('auth/send/password', { email })
  return response.data
}

export const setNewPin = async (data) => {
  await axios.put('auth/reset/pin', data)
  return 'Pin has been reset.'
}
export const setNewPassword = async (data) => {
  await axios.put('auth/reset/password', data)
  return 'Password has been reset.'
}
export const setNewUsername = async (data) => {
  await axios.put('auth/reset/username', data)
  return 'Username has been reset.'
}
export const setNewEmail = async (data) => {
  await axios.put('auth/reset/email', data)
  return 'Email has been reset.'
}
