import React from "react";
import MsgModal from "./MsgModal";
import RedBtn from "../button/RedBtn";
import { CloseIcon, ErrorMsgIcon, SuccessMsgIcon } from "../icons/icons";

export default function ErrorMsgModal({ open, message, isError, btnClick }) {
  return (
    <MsgModal open={open}>
      <div className="delete-modal-frame">
        <CloseIcon size="30" onClick={btnClick} />
        {isError ? <ErrorMsgIcon size={60} /> : <SuccessMsgIcon size={60} />}
        <h1 style={{ marginBottom: "50px" }}>{message}</h1>
        <RedBtn text="Close" width={150} height={40} onClickFunc={btnClick} />
      </div>
    </MsgModal>
  );
}
