import React from "react";
import kickappsLogo from "./../../images/kickapps-logo.svg";

function Footer() {
  return (
    <footer className="footer">
      <p>
        Copyright © 2023 Echo 8 Safety Consultants, LLC - All Rights Reserved.
      </p>
      <img src={kickappsLogo} alt="kickapps-Logo" />
    </footer>
  );
}

export default Footer;
