import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import ErrorSpan from '../../components/form/ErrorSpan'
import RedBtn from '../../components/button/RedBtn'
import WhiteBtn from '../../components/button/WhiteBtn'
import CheckinModal from '../../components/modal/CheckinModal'

import { searchAttendeeInfo } from '../../store/action/attendee.action'

export default function ClassCheckinFirst() {
  const { classDetail } = useSelector((state) => state.classes)
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm()

  useEffect(() => {
    if (!classDetail.id) {
      navigate('/classes/detail')
    }
  })
  const onSubmit = async (data) => {
    const checkInfo = await dispatch(searchAttendeeInfo(data))
    if (checkInfo) {
      nextCheckinFunc()
    }
  }
  const navigate = useNavigate()
  const backFunc = () => navigate(-1)
  const nextCheckinFunc = () => navigate('/classes/checkin2')

  const checkKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      const data = getValues()
      onSubmit(data)
    }
  }

  return (
    <CheckinModal open={true} className="one">
      <form
        className="modal-form"
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => checkKeyDown(e)}
      >
        <h2>Attendee Check-in</h2>
        {/* <div className="top"> */}
        {/* <div className="input-frame">
            <label for="firstName" style={{ display: 'block' }}>
              FIRST NAME
            </label>
            <input
              placeholder=""
              {...register('firstName', {
                required: true
              })}
            />
          </div>
          {errors.firstName ? <ErrorSpan>This field is required!</ErrorSpan> : ''} */}
        <div className="input-frame">
          <label for="lastName" style={{ display: 'block' }}>
            LAST NAME
          </label>
          <input
            // style={{ display: 'block' }}
            // id="lastName"
            placeholder=""
            {...register('lastName', {
              required: true
            })}
          />
        </div>
        {errors.lastName ? <ErrorSpan>This field is required!</ErrorSpan> : ''}
        {/* </div> */}

        <div className="input-frame">
          <label for="digits">LAST FOUR DIGITS SSN</label>
          <input
            // id="digits"
            placeholder=" "
            type="number"
            inputMode="numeric"
            maxLength={4}
            minLength={4}
            //input number still can type 'e'
            onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
            onInput={(e) => {
              if (e.target.value.length > e.target.maxLength)
                e.target.value = e.target.value.slice(0, e.target.maxLength)
            }}
            {...register('ssn', {
              required: 'This field is required!',
              minLength: { value: 4, message: 'Minimum 4 numbers' },
              maxLength: { value: 4, message: 'Maximum 4 numbers' }
            })}
          />
        </div>
        {errors.ssn && <ErrorSpan role="alert">{errors.ssn.message}</ErrorSpan>}
        <div className="btn-frame">
          <WhiteBtn
            text="Close"
            type="cancel"
            width={200}
            marginR={50}
            close={backFunc}
          />
          <RedBtn text="Next" type="submit" width={200} />
        </div>
      </form>
    </CheckinModal>
  )
}
