import axios from 'axios'

export const getHistoryList = async () => {
  const response = await axios.get('/api/history/all')
  return response.data
}

export const signupSheetData = async (id) => {
  const response = await axios.post('/api/history', { id })
  return response.data
}

export const addNewCertifiNumber = async (id, data) => {
  try {
    await axios.post('/api/history/cert/add', {
      class_id: id || 'no id provided',
      attendee_id: data.id,
      cert_number: data.cert_number
    })
    const newData = await axios.post('/api/history', { id })
    return newData
  } catch (error) {
    console.log('addNewCertifiNumber', error)
    return false
  }
}

export const updateCertifiNumber = async (id, data) => {
  try {
    await axios.post('/api/history/cert/update', {
      class_id: id || 'no id provided',
      attendee_id: data.id,
      cert_number: data.cert_number
    })
    const newData = await axios.post('/api/history', { id })
    return newData
  } catch (error) {
    console.log('updateCertifiNumber', error)
    return false
  }
}
