import React, { useMemo, useEffect, useState, useCallback, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import CheckinModal from '../../components/modal/CheckinModal'
import {
  getSignUpSheet,
  getClassDetail,
  closeClass
} from '../../store/action/classes.action'
import ErrorSpan from '../../components/form/ErrorSpan'
import RedBtn from '../../components/button/RedBtn'
import WhiteBtn from '../../components/button/WhiteBtn'
import ReactTableCheckbox from '../../components/table/ReactTableCheckbox'
import MsgModal from '../../components/modal/MsgModal'
import Signature from '../../components/tools/Signature'
import LoadingModal from '../../components/modal/LoadingModal'

export default function CheckSignUpSheet() {
  const { signUpSheetData, classDetail } = useSelector((state) => state.classes)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [sigShow, setSigShow] = useState(false)
  const padRef = useRef(null)
  const [canvas, setCanvas] = useState('')
  const [isLoading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors }
  } = useForm()
  useEffect(() => {
    const class_id = localStorage.getItem('classId')
    dispatch(getSignUpSheet(classDetail.id ? classDetail.id : class_id))
    if (classDetail.id) {
      dispatch(getClassDetail(classDetail.id))
    } else if (class_id) {
      dispatch(getClassDetail(class_id))
    }
  }, [dispatch])

  const clearSignatureCanvas = useCallback(() => {
    padRef?.current?.clear()
    setCanvas(undefined)
  }, [])
  const handleGetCanvas = useCallback(() => {
    const canvas = padRef?.current?.toDataURL()
    setValue('signature', canvas)
    setCanvas(canvas)
    setSigShow(false)
  }, [setValue])

  const backFunc = () => navigate(-1)
  const checkKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      const data = getValues()
      onSubmit(data)
    }
  }
  const searchHandler = (e) => {
    const class_id = localStorage.getItem('classId')
    dispatch(getSignUpSheet(classDetail.id ? classDetail.id : class_id, e.target.value))
  }

  const getRowId = (data) => {
    if (data) {
      const checkListArray = []
      data.forEach((i) => {
        if (data.length !== checkListArray.length) {
          return checkListArray.push(i.original)
        } else {
          return
        }
      })
      localStorage.setItem('checkListArray', JSON.stringify(checkListArray))
      //don't know why will make loops error
      // setCheckedList(checkListArray)
    }
  }

  const onSubmit = async (data) => {
    setLoading(true)
    const checkListArray = localStorage.getItem('checkListArray')
    const finalData = {
      signature: data.signature,
      checkList: JSON.parse(checkListArray)
    }
    setTimeout(() => {
      setLoading(false)
    }, 1000)
    await dispatch(closeClass(classDetail.id, finalData))
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Status',
        accessor: 'status',
        width: '10%',
        isSort: true,
        Cell: ({ row }) => {
          return <span>{row.isSelected ? 'Pass' : 'Fail'}</span>
        }
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
        width: '20%',
        isSort: true,
        Cell: ({ row }) => {
          return <span>{row.original.firstName}</span>
        }
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        width: '20%',
        isSort: true,
        Cell: ({ row }) => {
          return <span>{row.original.lastName}</span>
        }
      },
      {
        Header: 'SSN',
        accessor: 'ssn',
        width: '5%',
        isSort: true,
        Cell: ({ row }) => {
          return <span>{row.original.ssn}</span>
        }
      },
      {
        Header: 'Company',
        accessor: 'company',
        width: '30%',
        isSort: true,
        Cell: ({ row }) => {
          return <span>{row.original.company}</span>
        }
      }
    ],
    []
  )
  return (
    <>
      <CheckinModal open={true} className="signup-sheet">
        <ReactTableCheckbox
          columns={columns}
          data={signUpSheetData}
          tableSize={50}
          isShowPagination={false}
          title="Checking Attendee Sign Up Sheet"
          searchHandler={searchHandler}
          getRowId={getRowId}
          checkAllDefult={true}
        />
        <form
          className="modal-form"
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => checkKeyDown(e)}
        >
          <div className="input-frame">
            {canvas ? (
              <>
                <img
                  src={canvas}
                  alt=""
                  onClick={() => setSigShow(true)}
                  style={{ width: '200px', cursor: 'pointer' }}
                />
              </>
            ) : (
              <input
                placeholder="Please Sign Here"
                className="signature"
                inputMode="none"
                {...register('signature', {
                  required: true
                })}
                onClick={() => setSigShow(true)}
              />
            )}
          </div>
          {errors.signature ? <ErrorSpan>This field is required!</ErrorSpan> : ''}

          <div className="btn-frame">
            <WhiteBtn
              text="Close"
              type="cancel"
              width={200}
              marginR={50}
              close={backFunc}
            />
            <RedBtn text="Submit" type="submit" width={200} />
          </div>
        </form>
      </CheckinModal>
      {sigShow && (
        <MsgModal open={sigShow} cName="sign-modal">
          <Signature
            setSigShow={setSigShow}
            canvas={canvas}
            handleGetCanvas={handleGetCanvas}
            clearSignatureCanvas={clearSignatureCanvas}
            padRef={padRef}
            small={true}
          />
        </MsgModal>
      )}
      <LoadingModal color="#c13e30" open={isLoading} size={1000} />
    </>
  )
}
