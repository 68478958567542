import React, { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import logo from '../../images/echo8safety-logo.svg'
import ReactTable from './ReactTable'
import { getSignUpSheet } from '../../store/action/history.action'

export default function SignUpSheet({ printRef }) {
  const { signUpSheetDetail, signUpSheetID } = useSelector((state) => state.history)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSignUpSheet(signUpSheetID))
  }, [dispatch, signUpSheetID])

  const columns = useMemo(
    () => [
      {
        Header: 'Status',
        accessor: 'pass',
        width: '20%',
        isSort: true,
        Cell: ({ row }) => {
          return <span>{row.original.pass ? 'Pass' : 'Failed'}</span>
        }
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
        width: '20%',
        isSort: true,
        Cell: ({ row }) => {
          return <span>{row.original.firstName}</span>
        }
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        width: '20%',
        isSort: true,
        Cell: ({ row }) => {
          return <span>{row.original.lastName}</span>
        }
      },
      {
        Header: 'SSN',
        accessor: 'ssn',
        width: '10%',
        isSort: true,
        Cell: ({ row }) => {
          return <span>{row.original.ssn}</span>
        }
      },
      {
        Header: 'Company',
        accessor: 'company',
        width: '20%',
        isSort: true,
        Cell: ({ row }) => {
          return <span>{row.original.company}</span>
        }
      },
      {
        Header: 'Signature',
        accessor: 'signature',
        width: '20%',
        isSort: true,
        Cell: ({ row }) => {
          return (
            <img
              src={row.original.signature}
              alt=""
              style={{ width: '150px', height: '68px' }}
            />
          )
        }
      }
    ],
    []
  )
  return (
    <div
      className="certificate-template"
      ref={printRef}
      id="signUpSheetPDF"
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        color: 'black',
        padding: '20px 0px',
        position: 'relative'
      }}
    >
      <h1 style={{ fontSize: '2em', color: 'black' }}>{signUpSheetDetail.className}</h1>
      <img
        className="logo"
        src={logo}
        alt="Echo8Safety-Logo"
        style={{ width: '100px', margin: '20px 0' }}
      />
      <div
        className="info"
        style={{
          width: '90%',
          position: 'absolute',
          right: '0px',
          top: '100px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end'
        }}
      >
        <p style={{ fontSize: '0.6rem', width: '250px' }}>
          Address: 1302 Colfax Road Fairmont, WV 26554
        </p>
        <p style={{ fontSize: '0.6rem', width: '250px' }}>Phone: 607-731-0697</p>
        <p style={{ fontSize: '0.6rem', width: '250px' }}>
          Website: https://echo8safety.com/
        </p>
      </div>
      <ReactTable
        columns={columns}
        data={signUpSheetDetail.attendees ? signUpSheetDetail.attendees : []}
        // THIS WILL LIMIT HOW MANY ATTENDEES SHOW UP ON PAGE
        tableSize={50}
        isShowPagination={false}
        title="Class Sign In Sheet"
        isSorted={true}
        cName="signup-sheet"
        searchBarShow={false}
        style={{ color: 'black' }}
      />
      <div
        className="bottom"
        style={{ width: '90%', display: 'flex', flexDirection: 'column', color: 'black' }}
      >
        <div
          className="underline"
          style={{
            width: '200px',
            borderBottom: '2px solid black',
            display: 'flex',
            alignSelf: 'flex-end',
            justifyContent: 'center',
            padding: '10px 0',
            marginBottom: '5px'
          }}
        >
          <img
            style={{ width: '200px', height: '90px' }}
            src={
              signUpSheetDetail.sig_url === '#'
                ? 'https://echo8.s3.us-east-2.amazonaws.com/class_signatures/1686951059098_class_Opm6P9k4gnfqUM-zjDdhn'
                : signUpSheetDetail.sig_url
            }
            alt="Echo8Safety-Signature"
          />
        </div>
        <div
          className="underline"
          style={{
            width: '200px',
            display: 'flex',
            alignSelf: 'flex-end',
            justifyContent: 'center',
            padding: '10px 0',
            marginBottom: '5px'
          }}
        >
          <h3 style={{ fontSize: '1.17em', color: 'black' }}>Brian Coon</h3>
        </div>
        <div
          className="underline"
          style={{
            width: '200px',
            display: 'flex',
            alignSelf: 'flex-end',
            justifyContent: 'center',
            padding: '10px 0',
            marginBottom: '5px'
          }}
        >
          <h3 style={{ fontSize: '1.17em', color: 'black' }}>{signUpSheetDetail.date}</h3>
        </div>
      </div>
    </div>
  )
}
