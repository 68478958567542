import React from "react";

export default function RedBtn({
  text,
  onClickFunc,
  type,
  width,
  height,
  marginR,
  marginTop,
  id,
  value,
}) {
  return (
    <button
      className="red-btn"
      type={type}
      key={id}
      id={id}
      value={value}
      onClick={onClickFunc}
      style={{
        width: `${width}px`,
        height: `${height}px`,
        marginRight: `${marginR}px`,
        marginTop: `${marginTop}px`,
      }}
    >
      {text}
    </button>
  );
}
