import { useMemo } from 'react'
import { useTable, useSortBy, usePagination, useRowSelect } from 'react-table'
import { AscIcon, DescIcon } from '../icons/icons'
import Pagination from './Pagination'
import SearchBar from '../searchbar/SearchBar'
import MoonLoader from 'react-spinners/MoonLoader'
import RedBtn from '../button/RedBtn'

function ReactTable({
  columns,
  data,
  className,
  isSorted = false,
  isShowPagination = true,
  sortBy = [],
  cName,
  title,
  isRowClickable,
  searchHandler,
  selectedFlatRows,
  searchBarShow,
  isLoading,
  tableSize,
  csvMode,
  setCSVMode,
  style
}) {
  const initialState = useMemo(() => {
    return {
      pageSize: tableSize,
      pageIndex: 0,
      sortBy: [
        {
          id: 'col1',
          desc: false
        }
      ]
    }
  }, [])

  const tableInstance = useTable(
    { columns, data, initialState },
    useSortBy,
    usePagination,
    useRowSelect
  )

  const {
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    pageIndex,
    pageSize,
    prepareRow
  } = tableInstance

  return (
    <div className={['table-div', cName].join(' ')}>
      {csvMode ? (
        <div className="table-header csv">
          <div className="csv-mode-btn">
            <RedBtn
              text="CSV Mode"
              type="button"
              width={120}
              height={40}
              onClickFunc={() => setCSVMode(true)}
            />
          </div>
          <h2>{title}</h2>
          {searchBarShow && <SearchBar searchHandler={searchHandler} />}
        </div>
      ) : (
        <div className="table-header">
          <h2>{title}</h2>
          {searchBarShow && <SearchBar searchHandler={searchHandler} />}
        </div>
      )}
      <table {...getTableProps()} className={className}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => {
                const headerProps = isSorted
                  ? { ...column.getHeaderProps(column.getSortByToggleProps()) }
                  : {}
                return (
                  <th
                    {...headerProps}
                    className={[column.isSort ? 'active' : ''].join(' ')}
                    width={column.width}
                    key={i}
                  >
                    {column.render('Header')}
                    {column.checkbox ? column.render('checkbox') : null}
                    {isSorted && column.isSort ? (
                      <span className="sortBtn">
                        {column.canSort ? (
                          column.isSorted ? (
                            column.isSortedDesc ? (
                              <AscIcon size={20} />
                            ) : (
                              <DescIcon size={20} />
                            )
                          ) : (
                            ' '
                          )
                        ) : null}
                      </span>
                    ) : null}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        {/* Apply the table body props */}
        {isLoading ? (
          <tbody {...getTableBodyProps()}>
            <tr>
              {/* <td colSpan={0} className="no-data-text"> */}
              <td colSpan={columns.length} className="no-data-text">
                <div className="loading-frame">
                  <MoonLoader color={'#c13e30'} size={70} />
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody {...getTableBodyProps()}>
            {page.length > 0 ? (
              page.map((row, i) => {
                prepareRow(row)
                return (
                  <tr
                    className={[isRowClickable ? 'active' : ''].join(' ')}
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    })}
                  </tr>
                )
              })
            ) : (
              <tr>
                {/* <td colSpan={0} className="no-data-text"> */}
                <td colSpan={columns.length} className="no-data-text">
                  No Records
                </td>
              </tr>
            )}
          </tbody>
        )}
      </table>
      {isShowPagination && (
        <div className="pagination-div-right" style={style}>
          <Pagination
            data={data}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            pageCount={pageCount}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            pageIndex={pageIndex}
            pageSize={pageSize}
          />
        </div>
      )}
    </div>
  )
}

export default ReactTable
