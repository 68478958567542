import * as types from "../actionTypes";

export const setError = (msg, redireUrl) => {
  let payload = { msg };
  if (redireUrl) {
    payload = { ...payload, redireUrl, isLogout: true, msg: msg };
  }
  return {
    type: types.SET_ERROR,
    payload,
  };
};

export const setSuccessMsg = (msg, redireUrl) => {
  let payload = { msg };
  if (redireUrl) {
    payload = { ...payload, redireUrl, isError: false, msg: msg };
  }
  return {
    type: types.SET_SUCCESS_MSG,
    payload,
  };
};

export const getLoginAuthError = (msg, redireUrl) => {
  let payload = { msg };
  if (redireUrl) {
    payload = { ...payload, redireUrl, isLogout: true, msg: msg };
  }
  return {
    type: types.SET_ERROR,
    payload,
  };
};

export const setMsg = (msg, redireUrl) => {
  let payload = { msg };
  if (redireUrl) {
    payload = { ...payload, redireUrl, msg: msg };
  }
  return {
    type: types.SET_MESSAGE,
    payload,
  };
};
