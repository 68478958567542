import React from "react";
import { useController } from "react-hook-form";
const FormSelect = ({
  control,
  options,
  name,
  rules = {},
  disabled,
  change,
}) => {
  const {
    field: { ref, onChange, ...selectProps },
  } = useController({
    name,
    control,
    rules: { required: rules ? true : false },
    defaultValue: "",
  });
  return (
    <select
      name={name}
      ref={ref}
      {...selectProps}
      disabled={disabled}
      onChange={change}
    >
      {options.map((o, index) => {
        return (
          <option
            value={o.value}
            key={`${name}_select_${index}`}
            style={{ backgroundColor: "black" }}
          >
            {o.label}
          </option>
        );
      })}
    </select>
  );
};

export default FormSelect;
