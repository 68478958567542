import React, { useState, useEffect } from 'react'

const Pagination = ({
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  pageIndex,
  pageOptions,
  gotoPage,
  pageCount,
  setPageSize,
  pageSize,
  page
}) => {
  const [pageBtns, setPageBtns] = useState([])
  const [currentPage, setCurrentPage] = useState(0)

  useEffect(() => {
    // console.log('pageOptions', pageOptions)
    // const pageNumbers = []
    // const numPages = pageOptions.length
    // // Show the first five pages
    // for (let i = 0; i < 5; i++) {
    //   pageNumbers.push(i)
    // }
    // // Show the last page
    // pageNumbers.push(numPages - 1)
    // console.log('pageNumbers', pageNumbers)
    // setPageBtns(pageOptions)
  }, [pageOptions, pageIndex])

  const gotoThepage = (btn) => {
    setCurrentPage(btn)
    gotoPage(btn)
  }

  const previousPageFunc = () => {
    previousPage()
    setCurrentPage(currentPage - 1)
  }

  const gotoThepageFunc = (btn) => {
    setCurrentPage(btn)
    gotoThepage(btn)
  }
  const nextPageFunc = () => {
    nextPage()
    setCurrentPage(currentPage + 1)
  }

  return (
    // <div className="pagination_frame">
    //   <ul className="pagination">
    //     <li
    //       onClick={canPreviousPage ? () => previousPageFunc() : null}
    //       className={['preivous', canPreviousPage ? 'enable' : 'disabled'].join(' ')}
    //     >
    //       {'<'}
    //     </li>
    //     {pageBtns.map((btn, index) => {
    //       return btn === null ? (
    //         <li key={`pagination_btn_${index}`}>...</li>
    //       ) : (
    //         <li
    //           key={`pagination_btn_${index}`}
    //           className={['page', btn === currentPage ? 'active' : ''].join(' ')}
    //           onClick={() => gotoThepageFunc(btn)}
    //         >
    //           {btn + 1}
    //         </li>
    //       )
    //     })}
    //     <li
    //       onClick={() => (canNextPage ? nextPageFunc() : null)}
    //       className={['next', canNextPage ? 'enable' : 'disabled'].join(' ')}
    //     >
    //       {'>'}
    //     </li>
    //   </ul>
    // </div>
    <div className="pagination_frame">
      {/* <ul className="pagination"> */}
      <button
        className={['pagn-btn', canPreviousPage ? 'enable' : 'disabled'].join(' ')}
        onClick={() => gotoThepageFunc(0)}
        disabled={!canPreviousPage}
      >
        {'<<'}
      </button>{' '}
      <button
        className={['pagn-btn', canPreviousPage ? 'enable' : 'disabled'].join(' ')}
        onClick={() => previousPageFunc()}
        disabled={!canPreviousPage}
      >
        {'<'}
      </button>{' '}
      <span>
        Page{' '}
        <strong>
          {currentPage + 1} of {pageOptions.length}
        </strong>{' '}
      </span>
      <button
        className={['pagn-btn', canNextPage ? 'enable' : 'disabled'].join(' ')}
        onClick={() => nextPageFunc()}
        disabled={!canNextPage}
      >
        {'>'}
      </button>{' '}
      <button
        className={['pagn-btn', canNextPage ? 'enable' : 'disabled'].join(' ')}
        onClick={() => gotoThepageFunc(pageCount - 1)}
        disabled={!canNextPage}
      >
        {'>>'}
      </button>{' '}
      {/* </ul> */}
      {/* <span>
        | Go to page:{' '}
        <input
          type="number"
          defaultValue={pageIndex + 1}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0
            gotoPage(page)
          }}
        />
      </span>{' '} */}
      {/* <select
        value={pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value))
        }}
      >
        {[5].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select> */}
    </div>
  )
}

export default Pagination
