import axios from 'axios'
import dataURLtoFile from './axios/fileConverter'

const filterByReference = (arr1, arr2) => {
  let res = []
  res = arr1.filter((el) => {
    return !arr2.find((element) => {
      return element.id === el.id
    })
  })
  return res
}

export const getClassesList = async () => {
  const response = await axios.get('/api/class/all')
  return response.data
}

export const getClassDetailData = async (id) => {
  const response = await axios.post('/api/class', { id })
  return response.data
}

export const createNewClassData = async (data) => {
  await axios.post('/api/class/create', {
    trainingId: data.training_id,
    date: data.date,
    companyId: data.company_id
  })
  const response = await getClassesList()
  return response
}

export const deleteRowClassData = async (id) => {
  await axios.put('/api/class/delete', { id })
  const response = await axios.get('/api/class/all')

  return response.data
}

export const updateClassData = async (classId, data) => {
  await axios.put('/api/class/update', { classId, data })
  const response = await axios.get('/api/class/all')
  return response.data
}

export const getSignUpSheetDetail = async (classId) => {
  const response = await axios.post('/api/class/sheet', {
    classId
  })
  return response.data
}

export const closeClass = async (classId, data) => {
  const urlPrep = dataURLtoFile(data.signature, `class_${classId}`)
  const sig_url = await axios.post('/api/class/upload/sig', urlPrep)
  const classRoster = await axios.post('/api/class/sheet', {
    classId
  })

  const response = await axios.post('/api/class/close', {
    classId,
    sig_url: sig_url.data,
    passed: data.checkList.map((obj) => obj.id),
    failed: filterByReference(classRoster.data, data.checkList).map((obj) => obj.id)
  })
  return response.data
}
