import React, { useEffect, useMemo } from 'react'
import {
  useGlobalFilter,
  useMountedLayoutEffect,
  useRowSelect,
  useTable,
  useSortBy,
  usePagination
} from 'react-table'
import { useDispatch } from 'react-redux'
import { AscIcon, DescIcon } from '../icons/icons'
import Pagination from './Pagination'
import SearchBar from '../searchbar/SearchBar'
// import IndeterminateCheckbox from './IndeterminateCheckbox'
import RedBtn from '../button/RedBtn'
import WhiteBtn from '../button/WhiteBtn'
import { updateCSVList, getCSVFile } from '../../store/action/attendee.action'

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef()
  const resolvedRef = ref || defaultRef

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  )
})

const ReactCheckboxTable = ({
  columns,
  data,
  pageSize = 1,
  onChangeSelectedRowsId,
  searchKeyword,
  cName,
  csvMode,
  setCSVMode,
  title,
  searchHandler,
  isSorted = false,
  isShowPagination = true,
  tableSize,
  checkedList,
  setMsg,
  setError,
  setErrorModal,
  setCsvFileList,
  setOpenReview,
  checkAllDefult,
  className,
  isRowClickable
}) => {
  const initialState = useMemo(() => {
    return {
      pageSize: tableSize,
      pageIndex: 0,
      sortBy: [
        {
          id: 'col1',
          desc: false
        }
      ]
    }
  }, [tableSize])

  //exportCSVFunc
  const dispatch = useDispatch()

  const exportCSVFunc = async () => {
    if (Object.keys(selectedRowIds).length === 0) {
      setMsg('Please Select Attendee')
      setError(true)
      setErrorModal(true)
    } else {
      function filterDataByIndex(dataArray, indexObject) {
        const result = []
        for (const key in indexObject) {
          const index = parseInt(key)
          if (index >= 0 && index < dataArray.length) {
            result.push(dataArray[index])
          }
        }
        return result
      }

      const filteredData = filterDataByIndex(data, selectedRowIds)
      console.log('filteredData', filteredData)

      let selectedIDList = []
      filteredData.forEach((item) => {
        selectedIDList.push(item.id)
      })
      const selectedList = await dispatch(updateCSVList(selectedIDList))
      setCsvFileList(selectedList)
      if (selectedList) {
        // setOpenMsgModal(true)
        setOpenReview(true)
      }
    }
  }

  const {
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    pageIndex,
    prepareRow,
    setGlobalFilter,
    state: { selectedRowIds }
  } = useTable(
    {
      columns,
      data,
      initialState
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          width: '5%',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          )
        },
        ...columns
      ])
    }
  )

  useMountedLayoutEffect(() => {
    onChangeSelectedRowsId && onChangeSelectedRowsId(selectedRowIds)
  }, [selectedRowIds])

  //frontend search
  useEffect(() => {
    setGlobalFilter(searchKeyword)
  }, [searchKeyword])

  return (
    <div className={['table-div', cName].join(' ')}>
      {csvMode && (
        <div className="table-header csv">
          <div className="csv-mode-btn">
            <div className="btn-frame">
              <RedBtn
                text="Export"
                type="submit"
                width={100}
                height={40}
                marginR={10}
                onClickFunc={exportCSVFunc}
              />
              <WhiteBtn
                text="Cancel"
                type="cancel"
                width={100}
                height={40}
                close={() => setCSVMode(false)}
              />
            </div>
          </div>
          <h2>{title}</h2>
          <SearchBar searchHandler={searchHandler} />
        </div>
      )}
      <table {...getTableProps()} className={className}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => {
                const headerProps = isSorted
                  ? { ...column.getHeaderProps(column.getSortByToggleProps()) }
                  : {}
                return (
                  <th
                    {...headerProps}
                    className={[column.isSort ? 'active' : ''].join(' ')}
                    width={column.width}
                    key={i}
                  >
                    {column.render('Header')}
                    {column.checkbox ? column.render('checkbox') : null}

                    {isSorted && column.isSort ? (
                      <span className="sortBtn">
                        {column.canSort ? (
                          column.isSorted ? (
                            column.isSortedDesc ? (
                              <AscIcon size={20} />
                            ) : (
                              <DescIcon size={20} />
                            )
                          ) : (
                            ' '
                          )
                        ) : null}
                      </span>
                    ) : null}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.length > 0 ? (
            page.map((row, i) => {
              prepareRow(row)
              return (
                <tr
                  className={[isRowClickable ? 'active' : ''].join(' ')}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        style={row.isSelected ? { backgroundColor: '#c13e30' } : {}}
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={columns.length} className="no-data-text">
                No Records
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {isShowPagination && (
        <div className="pagination-div-right">
          <Pagination
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            pageCount={pageCount}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            pageIndex={pageIndex}
            pageSize={pageSize}
          />
        </div>
      )}
    </div>
  )
}

export default ReactCheckboxTable
