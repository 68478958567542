import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import AttendeeTable from '../../components/table/AttendeeTable'
import { CameraIcon } from '../../components/icons/icons'
import Camera from '../../components/tools/Camera'
import LoadingModal from '../../components/modal/LoadingModal'
import RedBtn from '../../components/button/RedBtn'
import { getAttendeeDetail } from '../../store/action/attendee.action'
import MsgModal from '../../components/modal/MsgModal'

export default function AttendeeDetail({ setEditAttendeeMode, editAttendeeMode }) {
  const { attendeeDetail } = useSelector((state) => state.attendee)
  const [cameraOpen, setCameraOpen] = useState(false)
  const [picture, setPicture] = useState('')
  const [isLoading, setLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    setLoading(true)
    if (!attendeeDetail) {
      const id = localStorage.getItem('attendee_id')
      dispatch(getAttendeeDetail(id))
    }
    const userPic = attendeeDetail.profile_image
    setPicture(userPic)
    setTimeout(() => {
      setLoading(false)
    }, [1000])
  }, [attendeeDetail.profile_image])

  const takePicFunc = () => {
    setCameraOpen(true)
  }
  const uploadImgFunc = () => {
    setPicture(picture)
    setCameraOpen(false)
  }
  return (
    <>
      <div className="attendee-detail">
        <div className="left">
          <div className="profolio">
            {picture ? (
              <div className="pic-frame">
                <img src={picture} alt="" />
                {/* <CameraIcon size={30} onClick={takePicFunc} /> */}
              </div>
            ) : (
              <CameraIcon size={40} onClick={takePicFunc} />
            )}
          </div>
          <div className="detail">
            <h1>{`${attendeeDetail.firstName} ${attendeeDetail.lastName}`}</h1>
            <p>Email</p>
            <h3> {attendeeDetail.email}</h3>
            <p>Phone Number</p>
            <h3> {attendeeDetail.phone_number}</h3>
            <p>Company</p>
            <h3> {attendeeDetail.company}</h3>
            <p>SSN</p>
            <h3>{attendeeDetail.ssn}</h3>
          </div>
        </div>
        <div className="right">
          <RedBtn
            text="Edit"
            width={80}
            height={30}
            onClickFunc={() => {
              setEditAttendeeMode(!editAttendeeMode)
            }}
          />
          <AttendeeTable />
        </div>
      </div>
      <LoadingModal color="#c13e30" open={isLoading} size={1000} />
      {cameraOpen && (
        <MsgModal open={cameraOpen}>
          <Camera
            picture={picture}
            setPicture={setPicture}
            setCameraOpen={setCameraOpen}
            uploadImgFunc={uploadImgFunc}
          />
        </MsgModal>
      )}
    </>
  )
}
