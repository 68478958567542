import * as types from "../actionTypes";

const initState = {
  loginUser: {},
  isUserLogin: false,
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.USER_LOGIN_INFO: {
      return {
        ...state,
        loginUser: { ...action.payload.loginUser },
      };
    }
    case types.USER_IS_LOGIN:
      return {
        ...state,
        isUserLogin: action.payload.isUserLogin,
      };
    default:
      return state;
  }
};

export default reducer;
