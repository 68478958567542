import axios from 'axios'

export const getTrainingsList = async () => {
  const response = await axios.get('/api/training/all')
  return response.data
}

export const getRowTraining = async (id) => {
  const response = await axios.post('/api/training', { id })
  return response.data
}

export const getTrainingSelectData = async () => {
  const response = await axios.get('/api/training/list')
  return response.data
}

export const createNewTrainingData = async (data) => {
  await axios.post('/api/training/create', {
    name: data.training_name,
    type: data.type,
    cert_number: data.certi_requred,
    expiration: data.expiration
  })
  const response = await axios.get('/api/training/all')
  return response.data
}

export const updateTrainingData = async (id, data) => {
  await axios.put('/api/training/update', {
    id,
    data: {
      name: data.training_name,
      type: data.type,
      cert_number: data.certi_requred,
      expiration: data.expiration
    }
  })

  const response = await axios.get('/api/training/all')
  return response.data
}

export const deleteRowTrainingData = async (id) => {
  await axios.put('/api/training/delete', { id })
  const response = await axios.get('/api/training/all')
  return response.data
}
