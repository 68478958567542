import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ReactTable from './ReactTable'
import RedBtn from '../button/RedBtn'
import {
  addNewCertifiNum,
  updatCertifiNum,
  switchBtn,
  attendeeCertAddEditData
} from '../../store/action/history.action'

export default function AttenndeeCertiNumAddTable({ class_id }) {
  const { classAttendeesHistorydata, attendeeCertAddEditDetail } = useSelector(
    (state) => state.history
  )
  const dispatch = useDispatch()
  useEffect(() => {
    localStorage.removeItem('attendeeData')
  })
  const CheckButton = (row) => {
    const handleAddEditClick = async () => {
      const data = localStorage.getItem('attendeeData')
      // const data = attendeeCertAddEditDetail
      if (row.original.canEdit) {
        await dispatch(switchBtn(class_id, row.original, row.original.canEdit))
      } else if (!row.original.cert_number) {
        await dispatch(addNewCertifiNum(class_id, JSON.parse(data)))
      } else if (row.original.cert_number && data) {
        await dispatch(updatCertifiNum(class_id, JSON.parse(data)))
        // await dispatch(updatCertifiNum(class_id))
      } else if (row.original.cert_number && !data) {
        await dispatch(switchBtn(class_id, row.original, row.original.canEdit))
      }
    }
    return (
      <>
        {row.original.cert_number ? (
          <RedBtn
            id={row.id}
            onClickFunc={handleAddEditClick}
            value={row.original.canEdit}
            text={row.original.canEdit ? 'Edit' : 'Save'}
            width={70}
            height={25}
          />
        ) : (
          <RedBtn
            id={row.id}
            onClickFunc={handleAddEditClick}
            value={row.original.canEdit}
            text={'Save'}
            width={70}
            height={25}
          />
        )}
      </>
    )
  }
  const CertifiNumInput = (row) => {
    const [inputValue, setInputValue] = useState('')
    const onchange = (e) => {
      setInputValue(e.target.value)
      const data = JSON.stringify({
        ...row.original,
        cert_number: e.target.value
      })
      localStorage.setItem('attendeeData', data)
      // const data = { ...row.original, cert_number: e.target.value }
      // dispatch(attendeeCertAddEditData(data))
    }
    if (row.original.cert_number && row.original.canEdit === false) {
      return (
        <input
          key={row.id}
          id={row.id}
          onChange={onchange}
          defaultValue={row.original.cert_number}
          minLength={3}
          maxLength={30}
          style={{ width: '90%' }}
        />
      )
    } else if (row.original.cert_number && row.original.canEdit === true) {
      return <span>{row.original.cert_number}</span>
    } else {
      return (
        <input
          key={row.id}
          id={row.id}
          onChange={onchange}
          value={inputValue}
          minLength={3}
          maxLength={30}
          style={{width:'90%'}}
        />
      )
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'First Name',
        accessor: 'firstName',
        width: '15%',
        isSort: true,
        Cell: ({ row }) => {
          return <span>{row.original.firstName}</span>
        }
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        width: '15%',
        isSort: true,
        Cell: ({ row }) => {
          return <span>{row.original.lastName}</span>
        }
      },
      {
        Header: 'Company',
        accessor: 'company',
        width: '20%',
        isSort: true,
        Cell: ({ row }) => {
          return <span>{row.original.company}</span>
        }
      },
      {
        Header: 'Status',
        accessor: 'pass',
        width: '5%',
        isSort: true,
        Cell: ({ row }) => {
          return <span>{row.original.pass ? 'Pass' : 'Failed'}</span>
        }
      },
      {
        Header: 'Certificate Num',
        accessor: 'cert_number',
        width: '10%',
        isSort: true,
        Cell: ({ row }) => {
          return row.original.pass ? <CertifiNumInput {...row} /> : null
        }
      },
      {
        Header: 'Save/Edit',
        accessor: 'canEdit',
        width: '10%',
        isSort: false,
        Cell: ({ row }) => {
          return row.original.pass ? <CheckButton {...row} /> : null
        }
      }
    ],
    []
  )
  return (
    <div className="certifi-add-table">
      <ReactTable
        columns={columns}
        data={classAttendeesHistorydata}
        tableSize={50}
        isShowPagination={false}
        title="History"
        isSorted={true}
        tableType="history"
        searchBarShow={false}
      />
    </div>
  )
}
