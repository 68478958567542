import React from 'react'
import { useForm } from 'react-hook-form'
import RedBtn from '../../components/button/RedBtn'
import ErrorSpan from '../../components/form/ErrorSpan'

export default function PortfolioPinCheck({ checkPinCorrect }) {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm()
  const checkKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      const data = getValues()
      handleSubmit(data)
    }
  }
  return (
    <div className="pin-frame">
      <h1>Please Enter PIN</h1>
      <form
        className="modal-form"
        onSubmit={handleSubmit(checkPinCorrect)}
        onKeyDown={(e) => checkKeyDown(e)}
      >
        <div className="input-frame pin">
          <input
            type="password"
            inputMode="numeric"
            autoComplete="on"
            maxLength={4}
            minLength={4}
            //input number still can type 'e'
            onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
            onInput={(e) => {
              if (e.target.value.length > e.target.maxLength)
                e.target.value = e.target.value.slice(0, e.target.maxLength)
            }}
            {...register('pin', {
              required: 'This field is required!',
              minLength: { value: 4, message: 'Minimum 4 numbers' },
              maxLength: { value: 4, message: 'Maximum 4 numbers' },
              pattern: { value: /^(0|[1-9]\d*)(\.\d+)?$/, message: 'Please Enter Number' }
            })}
          />
        </div>
        {errors.pin && <ErrorSpan role="alert">{errors.pin.message}</ErrorSpan>}
        <div className="btn-frame" style={{ marginTop: '0px' }}>
          <RedBtn text="Submit" type="submit" width={250} height={40} />
        </div>
      </form>
    </div>
  )
}
