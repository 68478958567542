import React from "react";
import { ErrorIcon } from "../icons/icons";

const ErrorSpan = ({ children, className }) => {
  return (
    <span className={["error", className].join(" ")}>
      <ErrorIcon />
      {children}
    </span>
  );
};

export default ErrorSpan;
