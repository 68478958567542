import React from 'react'
import { CSVLink } from 'react-csv'
// import { PrinterIcon } from '../../components/icons/icons'
import WhiteBtn from '../../components/button/WhiteBtn'
import ReviewCSVFile from '../../components/table/ReviewCSVFile'

export default function AttendeeCSVFileReview({ setOpenReview, csvFileList }) {
  return (
    <div className="certificate-frame" style={{ width: '90%' }}>
      {/* <div className="top">
        <PrinterIcon size="30" className="printer-icon" />
      </div> */}
      <div className="template-frame" style={{ height: '100%', overflow: 'auto' }}>
        <ReviewCSVFile />
      </div>
      <div className="btn-frame">
        <button
          className="red-btn"
          style={{
            width: '150px',
            height: '40px',
            marginRight: '40px'
          }}
        >
          <CSVLink
            filename={'Attendees Selected List.csv'}
            data={csvFileList}
            onClick={() => setOpenReview(false)}
          >
            Download
          </CSVLink>
        </button>
        <WhiteBtn
          text="Close"
          type="cancel"
          width={150}
          height={40}
          close={() => setOpenReview()}
        />
      </div>
    </div>
  )
}
