import React, { useState, useRef, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import LoadingModal from '../../components/modal/LoadingModal'
import ErrorSpan from '../../components/form/ErrorSpan'
import { CameraIcon } from '../../components/icons/icons'
import RedBtn from '../../components/button/RedBtn'
import WhiteBtn from '../../components/button/WhiteBtn'
import CheckinModal from '../../components/modal/CheckinModal'
import MsgModal from '../../components/modal/MsgModal'
import Signature from '../../components/tools/Signature'
import { Companies } from '../../utils/enums'
import FormSelect from '../../components/modal/FormSelect'
import Camera from '../../components/tools/Camera'
import { getCompanySelectList } from '../../store/action/companies.action'
import { updateCheckinInfo, createCheckinInfo } from '../../store/action/attendee.action'

export default function ClassCheckinSecond() {
  const { attendeeDetail } = useSelector((state) => state.attendee)
  const { classDetail } = useSelector((state) => state.classes)
  const { companySelectList } = useSelector((state) => state.companies)
  const [sigShow, setSigShow] = useState(false)
  const padRef = useRef(null)
  const [canvas, setCanvas] = useState('')
  const [signature, setSignature] = useState('')
  const [cameraOpen, setCameraOpen] = useState(false)
  const [picture, setPicture] = useState('')
  // const [msg, setMsg] = useState("");
  // const [isOpenModal, setOpenNodal] = useState(false);
  // const [isError, setError] = useState(false);
  // JUSTIN CODE, EXPERIMENT FOR API LISTS
  const [lists, setLists] = useState({
    companies: []
  })
  const [isLoading, setLoading] = useState(false)
  // JUSTIN CODE, EXPERIMENT FOR API LISTS
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({ defaultValues: { ...attendeeDetail }, mode: 'onTouched' })

  useEffect(() => {
    if (!classDetail.id) {
      navigate('/classes/detail')
    }
    dispatch(getCompanySelectList())
    if (attendeeDetail.profile_image) {
      setValue('profile_image', attendeeDetail.profile_image)
      setPicture(attendeeDetail.profile_image)
    }
    reset({
      ...attendeeDetail
    })
  }, [attendeeDetail, reset])

  // JUSTIN'S CODE, FETCHING LISTS
  useEffect(() => {
    const fetch = async () => {
      const companies = await Companies()

      setLoading(false)
      setLists({
        companies
      })
    }

    if (isLoading) {
      fetch()
    }
  }, [])

  const onSubmit = async (data) => {
    setLoading(true)
    if (attendeeDetail.id) {
      await dispatch(updateCheckinInfo(classDetail.id, data))
    } else {
      await dispatch(createCheckinInfo(classDetail.id, data))
    }
    setLoading(false)
  }
  const navigate = useNavigate()
  const backFunc = () => navigate(-1)

  const clearSignatureCanvas = useCallback(() => {
    padRef?.current?.clear()
    setCanvas(undefined)
  }, [])

  const handleGetCanvas = useCallback(() => {
    const canvas = padRef?.current?.toDataURL()
    setValue('sig_url', canvas)
    setValue('signature', canvas)
    setCanvas(canvas)
    setSignature(canvas)
    setSigShow(false)
  }, [setValue])

  const uploadImgFunc = () => {
    setCameraOpen(false)
  }
  const takePicFunc = () => {
    setCameraOpen(true)
  }
  const compantChangeFunc = (e) => {
    setValue('company_id', e.target.value)
  }
  const checkKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      const data = getValues()
      onSubmit(data)
    }
  }
  return (
    <>
      <CheckinModal open={true} className="two">
        <form
          className="modal-form"
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => checkKeyDown(e)}
        >
          <div className="checkin-main">
            <div className="left">
              <div className="input-frame">
                <input
                  placeholder="Attendee First Name"
                  {...register('firstName', {
                    required: true
                  })}
                />
              </div>
              {errors.firstName && <ErrorSpan>This field is required!</ErrorSpan>}
              <div className="input-frame">
                <input
                  placeholder="Attendee Last Name"
                  {...register('lastName', {
                    required: true
                  })}
                />
              </div>
              {errors.lastName && <ErrorSpan>This field is required!</ErrorSpan>}
              <div className="input-frame">
                <input
                  placeholder="Attendee Email"
                  type="email"
                  {...register('email', {
                    required: 'This field is required!',
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: 'Invalid email address'
                    }
                  })}
                />
              </div>
              {errors.email && <ErrorSpan role="alert">{errors.email.message}</ErrorSpan>}
              <div className="input-frame">
                <input
                  placeholder="Attendee Phone Number"
                  type="number"
                  inputMode="numeric"
                  maxLength={10}
                  minLength={10}
                  //input number still can type 'e'
                  onKeyDown={(e) =>
                    ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
                  }
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(0, e.target.maxLength)
                  }}
                  {...register('phone_number', {
                    minLength: { value: 10, message: 'Minimum 10 numbers' },
                    maxLength: { value: 10, message: 'Maximum 10 numbers' },
                    pattern: {
                      value: /^(0|[1-9]\d*)(\.\d+)?$/,
                      message: 'Please Enter Number'
                    }
                  })}
                />
              </div>
              {errors.phone_number && (
                <ErrorSpan role="alert">{errors.phone_number.message}</ErrorSpan>
              )}
              <div className="input-frame">
                <input
                  placeholder="Attendee Last Four Digits SSN"
                  type="number"
                  inputMode="numeric"
                  maxLength={4}
                  minLength={4}
                  //input number still can type 'e'
                  onKeyDown={(e) =>
                    ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
                  }
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(0, e.target.maxLength)
                  }}
                  {...register('ssn', {
                    required: 'This field is required!',
                    minLength: { value: 4, message: 'Minimum 4 numbers' },
                    maxLength: { value: 4, message: 'Maximum 4 numbers' }
                  })}
                />
              </div>
              {errors.ssn && <ErrorSpan role="alert">{errors.ssn.message}</ErrorSpan>}
              <FormSelect
                name="company_id"
                options={companySelectList}
                control={control}
                change={compantChangeFunc}
              />
              {errors.company_id && <ErrorSpan>This field is required!</ErrorSpan>}

              <div className="input-frame signature">
                {canvas ? (
                  <img src={canvas} alt="" onClick={() => setSigShow(true)} />
                ) : (
                  <input
                    placeholder="Attendee Signature"
                    className="signature"
                    inputMode="none"
                    {...register('signature', {
                      required: true
                    })}
                    onClick={() => setSigShow(true)}
                  />
                )}
              </div>
              {errors.signature ? <ErrorSpan>This field is required!</ErrorSpan> : ''}
            </div>
            <div className="right">
              <div className="input-frame">
                {!attendeeDetail.profile_image && (
                  <input
                    placeholder="Attendee Picture"
                    className="picture"
                    {...register('profile_image', {
                      required: true
                    })}
                  />
                )}
                <div className="profolio">
                  {picture ? (
                    <div className="pic-frame">
                      <img src={picture} alt="" />
                      <CameraIcon size={30} onClick={takePicFunc} />
                    </div>
                  ) : (
                    <CameraIcon size={40} onClick={takePicFunc} />
                  )}
                </div>
              </div>
              {errors.profile_image ? <ErrorSpan>This field is required!</ErrorSpan> : ''}
            </div>
          </div>
          <div className="btn-frame">
            <WhiteBtn
              text="Close"
              type="cancel"
              width={200}
              marginR={50}
              close={backFunc}
            />
            <RedBtn text="Submit" type="submit" width={200} />
          </div>
        </form>
      </CheckinModal>
      {sigShow && (
        <MsgModal open={sigShow} cName="sign-modal">
          <Signature
            setSigShow={setSigShow}
            canvas={canvas}
            handleGetCanvas={handleGetCanvas}
            clearSignatureCanvas={clearSignatureCanvas}
            padRef={padRef}
          />
        </MsgModal>
      )}
      {cameraOpen && (
        <MsgModal open={cameraOpen}>
          <Camera
            picture={picture}
            setPicture={setPicture}
            setCameraOpen={setCameraOpen}
            uploadImgFunc={uploadImgFunc}
            setValue={setValue}
          />
        </MsgModal>
      )}
      <LoadingModal color="#c13e30" open={isLoading} size={1000} />
    </>
  )
}
