import React, { useMemo, useState, useEffect, useCallback } from 'react'
// import { CSVLink } from 'react-csv'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../../components/layout/Layout'
import ReactTable from '../../components/table/ReactTable'
import ReactTableCheckbox from '../../components/table/ReactTableCheckbox'
import Modal from '../../components/modal/Modal'
// import { CloseIcon } from '../../components/icons/icons'
import AttendeeDetail from './AttendeeDetail'
import {
  getAllAttendee,
  getAttendeeDetail
  // updateCSVList,
  // getCSVFile
} from '../../store/action/attendee.action'
// import WhiteBtn from '../../components/button/WhiteBtn'
// import MsgModal from '../../components/modal/MsgModal'
import AttendeeCSVFileReview from './AttendeeCSVFileReview'
import ErrorMsgModal from '../../components/modal/ErrorMsgModal'
import AttendeeDetailEditForm from '../../components/form/AttendeeDetailEditForm'
import ReactCheckboxTable from '../../components/table/ReactCheckboxTable'

export default function AttendeesDashboard() {
  const { allAttendeeList } = useSelector((state) => state.attendee)
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(false)
  // const [openMsgModal, setOpenMsgModal] = useState(false)
  const [isCSVMode, setCSVMode] = useState(false)
  const [openReview, setOpenReview] = useState(false)
  const [checkedList, setCheckedList] = useState([])
  const [csvFileList, setCsvFileList] = useState([])
  const [msg, setMsg] = useState('')
  const [isErrorModal, setErrorModal] = useState(false)
  const [isError, setError] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const [editAttendeeMode, setEditAttendeeMode] = useState(false)
  const [tableData, setTableData] = useState([])
  const [searchKeyword, setSearchKeyword] = useState('')
  const [forceSelectionIdxs, setForceSelectionIdxs] = useState([])
  const [selectedRows, setSelectedRows] = useState([])

  useEffect(() => {
    setLoading(true)
    dispatch(getAllAttendee())
    if (allAttendeeList) {
      setTimeout(() => {
        setTableData(allAttendeeList)
        setLoading(false)
      }, [500])
    }
  }, [dispatch])

  useEffect(() => {
    const selected = tableData.filter((idxn, idx) => {
      return Object.keys(forceSelectionIdxs).some((id) => {
        return idx === Number(id)
      })
    })
    setSelectedRows(selected)
  }, [forceSelectionIdxs])

  const fetchAttendeeDetail = useCallback(
    async (data) => {
      localStorage.setItem('attendee_id', data)
      const attendeeDetail = await dispatch(getAttendeeDetail(data))
      if (attendeeDetail) {
        setOpenModal(true)
      }
    },
    [dispatch]
  )

  const getRowId = (data) => {
    if (data) {
      setCheckedList(data)
    }
  }

  const searchHandler = (e) => {
    dispatch(getAllAttendee(e.target.value, checkedList))
  }
  const checkboxSearchHandler = (e) => {
    setSearchKeyword(e.target.value)
  }
  // const reviewCSVFunc = () => {
  //   setOpenMsgModal(false)
  //   setOpenReview(true)
  // }
  const closeReviewCSVFunc = () => {
    setOpenReview(false)
    // setOpenMsgModal(true)
  }
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'firstName',
        width: '10%',
        isSort: true,
        Cell: ({ row }) => {
          return (
            <span onClick={() => fetchAttendeeDetail(row.original.id)}>
              {`${row.original.firstName + ' ' + row.original.lastName}`}
            </span>
          )
        }
      },
      {
        Header: 'Email',
        accessor: 'email',
        width: '20%',
        isSort: true,
        Cell: ({ row }) => {
          return (
            <span onClick={() => fetchAttendeeDetail(row.original.id)}>
              {row.original.email}
            </span>
          )
        }
      },
      {
        Header: 'Phone',
        accessor: 'phone_number',
        width: '5%',
        isSort: true,
        Cell: ({ row }) => {
          return (
            <span onClick={() => fetchAttendeeDetail(row.original.id)}>
              {row.original.phone_number}
            </span>
          )
        }
      },
      {
        Header: 'SSN',
        accessor: 'ssn',
        width: '5%',
        isSort: true,
        Cell: ({ row }) => {
          return (
            <span onClick={() => fetchAttendeeDetail(row.original.id)}>
              {row.original.ssn}
            </span>
          )
        }
      },
      {
        Header: 'Company',
        accessor: 'company',
        width: '20%',
        isSort: true,
        Cell: ({ row }) => {
          return (
            <span onClick={() => fetchAttendeeDetail(row.original.id)}>
              {row.original.company}
            </span>
          )
        }
      }
    ],
    [fetchAttendeeDetail]
  )
  return (
    <>
      <Layout mainPage="dashboard" page="attendees" title="Dashboard" isCreateBtn={false}>
        {isCSVMode ? (
          <>
            {/* <ReactTableCheckbox
              columns={columns}
              data={allAttendeeList}
              tableSize={5}
              isShowPagination={true}
              title="Attendees"
              isSorted={true}
              tableType="attendees"
              isRowClickable={true}
              searchHandler={searchHandler}
              searchBarShow={true}
              getRowId={getRowId}
              setCheckedList={setCheckedList}
              csvMode={true}
              setCSVMode={setCSVMode}
              isCSVMode={isCSVMode}
              checkedList={checkedList}
              setMsg={setMsg}
              setError={setError}
              setErrorModal={setErrorModal}
              setCsvFileList={setCsvFileList}
              // setOpenMsgModal={setOpenMsgModal}
              setOpenReview={setOpenReview}
            /> */}
            <ReactCheckboxTable
              columns={columns}
              data={allAttendeeList}
              onChangeSelectedRowsId={(selectedIds) => {
                setForceSelectionIdxs(selectedIds)
              }}
              searchKeyword={searchKeyword}
              tableSize={5}
              isShowPagination={true}
              title="Attendees"
              isSorted={true}
              tableType="attendees"
              isRowClickable={true}
              searchHandler={checkboxSearchHandler}
              searchBarShow={true}
              getRowId={getRowId}
              setCheckedList={setCheckedList}
              csvMode={true}
              setCSVMode={setCSVMode}
              isCSVMode={isCSVMode}
              checkedList={checkedList}
              setMsg={setMsg}
              setError={setError}
              setErrorModal={setErrorModal}
              setCsvFileList={setCsvFileList}
              // setOpenMsgModal={setOpenMsgModal}
              setOpenReview={setOpenReview}
            />
          </>
        ) : (
          <ReactTable
            columns={columns}
            data={allAttendeeList}
            tableSize={5}
            isShowPagination={true}
            title="Attendees"
            isSorted={true}
            tableType="attendees"
            isRowClickable={true}
            searchHandler={searchHandler}
            searchBarShow={true}
            isLoading={isLoading}
            setLoading={setLoading}
            csvMode={true}
            setCSVMode={setCSVMode}
            isCSVMode={isCSVMode}
          />
        )}
      </Layout>
      {openModal && (
        <Modal
          open={openModal}
          close={() => setOpenModal(false)}
          title={editAttendeeMode ? 'Edit Attendee Profile' : 'Attendee Profile'}
          wSize={100}
          hSize={100}
        >
          {editAttendeeMode ? (
            <AttendeeDetailEditForm
              setEditAttendeeMode={setEditAttendeeMode}
              editAttendeeMode={editAttendeeMode}
            />
          ) : (
            <AttendeeDetail
              setEditAttendeeMode={setEditAttendeeMode}
              editAttendeeMode={editAttendeeMode}
            />
          )}
        </Modal>
      )}
      {/* {openMsgModal && (
        <MsgModal open={openMsgModal}>
          <div className="delete-modal-frame">
            <CloseIcon size="30" onClick={() => setOpenMsgModal(false)} />
            <h1>Export the CSV File?</h1>
            <div className="btn-frame">
              <WhiteBtn
                text="Review"
                width={150}
                height={40}
                marginR={40}
                close={reviewCSVFunc}
              />
              <button
                className="red-btn"
                style={{
                  width: '150px',
                  height: '40px',
                  marginRight: '40px'
                }}
              >
                <CSVLink
                  filename={'Attendees Selected List.csv'}
                  data={csvFileList}
                  onClick={() => setOpenMsgModal(false)}
                >
                  Download
                </CSVLink>
              </button>
            </div>
          </div>
        </MsgModal>
      )} */}
      {openReview && (
        <Modal
          open={openReview}
          close={() => closeReviewCSVFunc()}
          title="Review CSV File"
          wSize={100}
          hSize={80}
        >
          <AttendeeCSVFileReview
            setOpenReview={closeReviewCSVFunc}
            csvFileList={csvFileList}
          />
        </Modal>
      )}
      {msg && (
        <ErrorMsgModal
          open={isErrorModal}
          message={msg}
          isError={isError}
          btnClick={() => setErrorModal(false)}
        />
      )}
    </>
  )
}
