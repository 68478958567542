import * as types from '../actionTypes'
import * as api from '../../api/trainingsApi'
import { setError } from './message.action'
import { setSuccessMsg } from './message.action'
export function getAllTrainings(searchArgs) {
  return async (dispatch) => {
    try {
      let allTrainingsData = await api.getTrainingsList()
      if (searchArgs) {
        allTrainingsData = Object.keys(searchArgs).reduce(
          (pre, key) => {
            pre = pre.filter((item) => {
              if ((item.expiration / 12).toString() === searchArgs) {
                return true
              }
              if (
                item.training_name
                  .toLocaleLowerCase()
                  .includes(searchArgs.toLocaleLowerCase())
              ) {
                return true
              }
              if (
                item.type.toLocaleLowerCase().includes(searchArgs.toLocaleLowerCase())
              ) {
                return true
              }
              return Object.values(item).includes(searchArgs)
            })
            return pre
          },
          [...allTrainingsData]
        )
      }
      dispatch(ListSuccess(allTrainingsData))
    } catch (err) {
      console.log('err', err)
      if (err.response.data.message === 'Unauthorized!') {
        dispatch(setError('Please Login Again', '/'))
      }
    }
  }
}
export function searchArgsChange(searchArgs) {
  return {
    type: types.TRAINING_ARGS_INUPTCHANGE,
    payload: {
      searchArgs
    }
  }
}

export function ListSuccess(data) {
  return {
    type: types.TRAININGS_LIST,
    payload: {
      allTrainingsList: data
    }
  }
}

export function blankTrainingData() {
  const trainingData = {
    training_name: '',
    type: '',
    price: '',
    expiration: ''
  }
  return {
    type: types.TRAINING_BLANK,
    payload: {
      trainingsDetail: trainingData
    }
  }
}

export function getRowTraining(data) {
  return async (dispatch) => {
    try {
      const trainingsDetailData = await api.getRowTraining(data)
      dispatch(getRowTrainingSuccess(trainingsDetailData))
    } catch (err) {
      console.log('err', err)
    }
  }
}
export function getRowTrainingSuccess(data) {
  return {
    type: types.TRAINING_ROW_DATA,
    payload: {
      trainingsDetail: data
    }
  }
}

export function deleteRowTraining(data) {
  return async (dispatch) => {
    try {
      const trainingDeleteData = await api.deleteRowTrainingData(data)
      dispatch(ListSuccess(trainingDeleteData))
      dispatch(setSuccessMsg('Training Deleted Successfully', '/trainings'))
    } catch (err) {
      console.log('err', err)
      dispatch(setError('Something went wrong, please try again'))
    }
  }
}

export function createNewTraining(data) {
  return async (dispatch) => {
    try {
      const trainingCreateData = await api.createNewTrainingData(data)
      dispatch(ListSuccess(trainingCreateData))
      dispatch(setSuccessMsg('Training Created Successfully!', '/trainings'))
    } catch (err) {
      console.log('err', err)
      dispatch(setError('Something went wrong, please try again'))
    }
  }
}

export function updateTraining(id, data) {
  return async (dispatch) => {
    try {
      const trainingUpdateData = await api.updateTrainingData(id, data)
      dispatch(ListSuccess(trainingUpdateData))
      dispatch(setSuccessMsg('Training Updated Successful!', '/trainings'))
    } catch (err) {
      console.log('err', err)
      dispatch(setError('Something went wrong, please try again'))
    }
  }
}

export function getTrainingSelectList(type) {
  return async (dispatch) => {
    try {
      const trainingSelectData = await api.getTrainingSelectData()

      if (type) {
        const filteredList = trainingSelectData.filter((obj) => obj.type === type)
        const finalList = [
          { value: '', label: 'Pick your Training', type: '' },
          ...filteredList
        ]
        dispatch(getTrainingSelectListSuccess(finalList))
      } else {
        const finalList = [
          { value: '', label: 'Pick your Training', type: '' },
          ...trainingSelectData
        ]
        dispatch(getTrainingSelectListSuccess(finalList))
      }
    } catch (err) {
      console.log('err', err)
    }
  }
}

export function getTrainingSelectListSuccess(data) {
  return {
    type: types.TRAINING_SELECT_LIST,
    payload: {
      trainingsSelectList: data
    }
  }
}
