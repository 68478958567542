import React, { useState } from "react";
import Footer from "../../components/layout/Footer";
import Logo from "../../images/echo8safety-logo.svg";
import LoginForm from "../../components/form/LoginForm";
import ResetUsernameForm from "../../components/form/ResetUsernameForm";
import ResetPasswordForm from "../../components/form/ResetPasswordForm";

function Login() {
  const [isUserNModalOpen, setIsUserNModalOpen] = useState(false);
  const [isPassWModalOpen, setIsPassWModalOpen] = useState(false);

  return (
    <>
      <div className="login-frame">
        <div className="form-frame login">
          <img className="logo" src={Logo} alt="Echo8Safety-Logo" />
          <LoginForm />
          <div className="reset-section">
            <button onClick={() => setIsUserNModalOpen(true)}>
              Forgot Username
            </button>
            <button onClick={() => setIsPassWModalOpen(true)}>
              Forgot Password
            </button>
          </div>
        </div>
      </div>
      <Footer />
      {isUserNModalOpen && (
        <ResetUsernameForm
          open={isUserNModalOpen}
          setIsUserNModalOpen={setIsUserNModalOpen}
        />
      )}
      {isPassWModalOpen && (
        <ResetPasswordForm
          open={isPassWModalOpen}
          setIsPassWModalOpen={setIsPassWModalOpen}
        />
      )}
    </>
  );
}

export default Login;
