import axios from 'axios'
import dataURLtoFile from './axios/fileConverter'

export const getAttendeeList = async () => {
  const response = await axios.get('/api/attendee/all')
  return response.data
}

export const getAttendeeDetailData = async (id) => {
  const response = await axios.post('/api/attendee', { id })
  console.log(response)
  return response.data
}

export const updateAttendeeDetailData = async (id, data) => {
  await axios.put('/api/attendee/update', {
    nanoid: id,
    email: data.email,
    phone_number: data.phone_number,
    company_id: data.company_id
  })
  const response = await axios.post('/api/attendee', { id })
  return response.data
}

export const checkInSearshAttendeeApi = async (data) => {
  const response = await axios.post('/api/attendee/find', {
    lastName: data.lastName,
    ssn: data.ssn
  })
  return response.data
}

export const createCheckinInfoApi = async (class_id, data) => {
  const imageFile = dataURLtoFile(
    data.profile_image,
    `${data.firstName}_${data.lastName}`
  )
  const sigFile = dataURLtoFile(data.sig_url, `${data.firstName}_${data.lastName}_sig`)

  const imageUrl = await axios.post('/api/attendee/upload/image', imageFile)
  const sigUrl = await axios.post('/api/attendee/upload/sig', sigFile)

  const sendData = {
    ...data,
    profile_image: imageUrl.data,
    sig_url: sigUrl.data,
    class_id
  }
  delete sendData.signature
  delete sendData.picture
  const response = await axios.post('/api/attendee/create', sendData)
  return response.data
}

export const updateCheckinInfoApi = async (class_id, data) => {
  const picRes = await axios.post('/api/attendee/pic', { id: data.id })
  const { profile_image } = picRes.data
  const sendData = { ...data, class_id }

  if (profile_image !== data.profile_image) {
    const newImage = dataURLtoFile(
      data.profile_image,
      `${data.firstName}_${data.lastName}`
    )
    const imageUrl = await axios.post('/api/attendee/upload/image', newImage)
    sendData.profile_image = imageUrl.data
  }
  const sigFile = dataURLtoFile(data.sig_url, `${data.firstName}_${data.lastName}_sig`)
  const sig_url = await axios.post('/api/attendee/upload/sig', sigFile)
  sendData.sig_url = sig_url.data
  delete sendData.signature
  delete sendData.picture
  const response = await axios.post('/api/attendee/update', sendData)
  return response.data
}

export const getAttendeeCSVTableList = async (data) => {
  const response = await axios.post('/api/attendee/csv/table', {
    data
  })
  return response.data
}

export const deleteCheckedAttendee = async (deleteId, class_id) => {
  const response = await axios.put('/api/attendee/delete', {
    deleteId,
    class_id
  })
  return response.data
}
