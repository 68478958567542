import * as types from "../actionTypes";

const initState = {
  allClassesList: [],
  searchArgs: {},
  classDetail: {},
  signUpSheetData: [],
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.CLASSES_LIST: {
      return { ...state, allClassesList: [...action.payload.allClassesList] };
    }
    case types.CLASS_DETAIL_INFO: {
      return { ...state, classDetail: { ...action.payload.classDetail } };
    }
    case types.CLASS_ARGS_INUPTCHANGE: {
      return {
        ...state,
        ...action.payload,
        searchArgs: { ...action.payload.searchArgs },
      };
    }
    case types.CLASS_BLANK: {
      return { ...state, classDetail: { ...action.payload.classDetail } };
    }
    case types.CLASS_SIGNUPSHEET_DETAIL: {
      return {
        ...state,
        signUpSheetData: [...action.payload.signUpSheetData],
      };
    }
    default:
      return state;
  }
};

export default reducer;
