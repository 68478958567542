import React, { useMemo, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ReactTable from './ReactTable'
import { ShowStatus, StatusColor } from '../../utils/enums'
import { filterAttendeeDetail } from '../../store/action/attendee.action'

export default function AttendeeTable() {
  const { attendeeDetail } = useSelector((state) => state.attendee)
  const [isLoading, setLoading] = useState(false)
  const [classList, setClassList] = useState([])

  const dispatch = useDispatch()

  useEffect(() => {
    setLoading(true)
    setClassList(attendeeDetail.classes)
    setTimeout(() => {
      setLoading(false)
    }, [1500])
  }, [])

  const searchHandler = (e) => {
    dispatch(filterAttendeeDetail(attendeeDetail.id, e.target.value))
  }
  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date',
        width: '25%',
        isSort: true,
        Cell: ({ row }) => {
          return <span>{row.original.date}</span>
        }
      },
      {
        Header: 'Class Name',
        accessor: 'className',
        width: '30%',
        isSort: true,
        Cell: ({ row }) => {
          return <span>{row.original.className}</span>
        }
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: '15%',
        isSort: true,
        Cell: ({ row }) => {
          return (
            <span className={StatusColor[row.original.status]}>
              {ShowStatus[row.original.status]}
            </span>
          )
        }
      },
      {
        Header: 'Expiration',
        accessor: 'expDate',
        width: '25%',
        isSort: true,
        Cell: ({ row }) => {
          return <span>{row.original.expDate}</span>
        }
      }
    ],
    []
  )
  return (
    <ReactTable
      columns={columns}
      data={classList}
      tableSize={5}
      isShowPagination={true}
      title="Class History"
      searchHandler={searchHandler}
      searchBarShow={true}
      isLoading={isLoading}
    />
  )
}
