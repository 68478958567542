import * as types from "../actionTypes";

const initState = {
  msg: null,
  isOpenModal: false,
  isLogout: false,
  isError: false,
  redireUrl: "",
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.SET_ERROR: {
      return {
        ...action.payload,
        isError: true,
        isOpenModal: true,
      };
    }
    case types.CLEAR_MSG: {
      return {
        msg: null,
        isError: false,
        isOpenModal: false,
      };
    }
    case types.SET_SUCCESS_MSG: {
      return {
        ...action.payload,
        isOpenModal: true,
        isLogout: false,
      };
    }
    case types.SET_MESSAGE: {
      return { ...action.payload, isOpenModal: true };
    }
    default:
      return state;
  }
};

export default reducer;
