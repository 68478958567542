import React, { useState, useEffect } from "react";
import SubMenuBtn from "../button/SubMenuBtn";

export default function SubMenu({ page }) {
  const [isClassesPage, setClassesPage] = useState(false);
  const [isAttendeePage, setAttendeePage] = useState(false);
  const [isTrainingsPage, setTrainingsPage] = useState(false);
  const [isCompanyPage, setCompanyPage] = useState(false);
  const [isHistoryPage, setHistoryPage] = useState(false);

  useEffect(() => {
    switch (page) {
      case "classes":
        return setClassesPage(true);
      case "attendees":
        return setAttendeePage(true);
      case "trainings":
        return setTrainingsPage(true);
      case "company":
        return setCompanyPage(true);
      default:
        return setHistoryPage(true);
    }
  }, [page]);
  
  return (
    <ul className="sub-menu">
      <SubMenuBtn title="Classes" page={isClassesPage} to="/classes" />
      <SubMenuBtn title="Attendee" to="/attendees" page={isAttendeePage} />
      <SubMenuBtn
        title="Trainings/Services"
        to="/trainings"
        page={isTrainingsPage}
      />
      <SubMenuBtn title="Companies" to="/companies" page={isCompanyPage} />
      <SubMenuBtn title="History" to="/history" page={isHistoryPage} />
    </ul>
  );
}
