import * as types from '../actionTypes'
import * as api from '../../api/attendeeApi'
import { setError, setSuccessMsg } from './message.action'
import { getClassDetail } from './classes.action'
import { ShowStatus } from '../../utils/enums'

export function getAllAttendee(searchArgs) {
  return async (dispatch) => {
    try {
      let allAttendeeData = await api.getAttendeeList()
      if (searchArgs) {
        allAttendeeData = Object.keys(searchArgs).reduce(
          (pre, key) => {
            pre = pre.filter((item) => {
              if (item.ssn.toString().includes(searchArgs)) {
                return true
              }
              if (
                item.firstName
                  .toLocaleLowerCase()
                  .includes(searchArgs.toLocaleLowerCase())
              ) {
                return true
              }
              if (
                item.lastName.toLocaleLowerCase().includes(searchArgs.toLocaleLowerCase())
              ) {
                return true
              }
              if (
                item.company.toLocaleLowerCase().includes(searchArgs.toLocaleLowerCase())
              ) {
                return true
              }
              return Object.values(item).includes(searchArgs)
            })
            return pre
          },
          [...allAttendeeData]
        )
      }
      dispatch(getAllAttendeeListSuccess(allAttendeeData))
    } catch (err) {
      console.log('getAllAttendee err', err)
      if (err.response.data.message === 'Unauthorized!') {
        dispatch(setError('Please Login Again', '/'))
      }
    }
  }
}

export function searchArgsChange(searchArgs) {
  return {
    type: types.ATTENDEE_ARGS_INUPTCHANGE,
    payload: {
      searchArgs
    }
  }
}

export function getAllAttendeeListSuccess(data) {
  return {
    type: types.ATTENDEE_LIST,
    payload: {
      allAttendeeList: data
    }
  }
}

export function getAttendeeDetail(data) {
  return async (dispatch, getState) => {
    try {
      let attendeeDetail = await api.getAttendeeDetailData(data)
      dispatch(getAttendeeDetailSuccess(attendeeDetail))
      return attendeeDetail
    } catch (err) {
      console.log('err', err)
      dispatch(setError('Something went wrong, please try again'))
    }
  }
}

export function updateAttendeeDetail(id, data) {
  return async (dispatch) => {
    try {
      const atendeeDetailUpdateData = await api.updateAttendeeDetailData(id, data)
      dispatch(getAttendeeDetailSuccess(atendeeDetailUpdateData))
      dispatch(setSuccessMsg('Attendee Profile Updated Successfully', 'attendees'))
    } catch (err) {
      console.log('err', err)
      dispatch(setError('Something went wrong, please try again'))
    }
  }
}

export function filterAttendeeDetail(id, searchArgs) {
  return async (dispatch) => {
    try {
      let attendeeDetail = await api.getAttendeeDetailData(id)
      if (!searchArgs) {
        return attendeeDetail.classes
      } else {
        attendeeDetail.classes = Object.keys(searchArgs).reduce(
          (pre, key) => {
            pre = pre.filter((item) => {
              if (item.date.toString().includes(searchArgs)) {
                return true
              }
              if (item.expDate.toString().includes(searchArgs)) {
                return true
              }
              if (item.className.toLocaleLowerCase().includes(searchArgs)) {
                return true
              }
              const searchArgLower = searchArgs.toLowerCase()
              const statusValue = ShowStatus[item.status]
              const statusValueLower = statusValue.toLowerCase()
              if (statusValueLower.includes(searchArgLower)) {
                return true
              }

              return Object.values(item).includes(searchArgs)
            })
            return pre
          },
          [...attendeeDetail.classes]
        )
      }

      dispatch(getAttendeeDetailSuccess(attendeeDetail))
    } catch (err) {
      console.log('err', err)
    }
  }
}

export function updaloadAttendeePic(id, picture) {
  return async (dispatch) => {
    try {
      let allAttendeeData = await api.getAttendeeList()
      let attendeeDetail = allAttendeeData.find((i) => i.id === id)

      for (const key in attendeeDetail) {
        if (key === 'pic') attendeeDetail[key] = picture
      }
      dispatch(getAttendeeDetailSuccess(attendeeDetail))
    } catch (err) {
      console.log('err', err)
      dispatch(setError('Something went wrong, please try again'))
    }
  }
}

export function getAttendeeDetailSuccess(data) {
  return {
    type: types.ATTENDEE_ROW_DATA,
    payload: {
      attendeeDetail: data
    }
  }
}

export function getAttendeeClassSuccess(data) {
  return {
    type: types.ATTENDEE_CLASS_LIST,
    payload: {
      attendeeClassList: data
    }
  }
}

export function searchAttendeeInfo(data) {
  return async (dispatch, getState) => {
    try {
      let attendeeDetail = await api.checkInSearshAttendeeApi(data)
      if (attendeeDetail.id) {
        dispatch(getAttendeeDetailSuccess(attendeeDetail))
        return attendeeDetail
      } else {
        dispatch(blankAttendeeData(data))
        return attendeeDetail
      }
    } catch (err) {
      console.log('err', err)
    }
  }
}

export function searchAttendeeInfoSuccess(data) {
  return {
    type: types.ATTENDEE_ROW_DATA,
    payload: {
      attendeeClassList: data
    }
  }
}

export function blankAttendeeData(data) {
  const attendeeDetail = {
    firstName: '',
    lastName: data.lastName,
    email: '',
    phone_number: '',
    ssn: data.ssn,
    company_id: '',
    sig_url: '',
    profile_image: ''
  }
  return {
    type: types.ATTENDEE_BLANK,
    payload: {
      attendeeDetail: attendeeDetail
    }
  }
}

export function updateCheckinInfo(class_id, data) {
  return async (dispatch, getState) => {
    try {
      const updateAttendeeDetail = await api.updateCheckinInfoApi(class_id, data)
      dispatch(setSuccessMsg("You're checked in!", '/classes/detail'))
      return updateAttendeeDetail
    } catch (err) {
      console.log('err', err)
      if (err.response.status === 500) {
        dispatch(setError('Attendee signup false!(update)', '/classes/detail'))
      }
      dispatch(setError('Something went wrong, please try again', '/classes/detail'))
    }
  }
}

export function createCheckinInfo(class_id, data) {
  return async (dispatch, getState) => {
    try {
      await api.createCheckinInfoApi(class_id, data)
      dispatch(setSuccessMsg("You're checked in!", '/classes/detail'))
    } catch (err) {
      console.log('err', err)
      dispatch(getClassDetail(class_id))
      dispatch(setError('Some went wrong, please try again', '/classes/detail'))
    }
  }
}

export function updateCSVList(data) {
  // selected data will be a id list['_mHPCEZEAU8QZ209fxEBc', 'qsafV6_hXe335HljKq1_S']
  return async (dispatch, getState) => {
    try {
      let getAttendeeCSVTableListData = await api.getAttendeeCSVTableList(data)

      //the table dynamic header backend should return this
      const headerList = getAttendeeCSVTableListData.header.reduce((pre, item) => {
        return [...pre, { Header: item, accessor: item }]
      }, [])
      dispatch(updateCSVListSuccess(getAttendeeCSVTableListData.data))
      dispatch(updateCSVTableHeaseSuccess(headerList))
      return getAttendeeCSVTableListData.data
    } catch (err) {
      console.log('err', err)
    }
  }
}

export function updateCSVListSuccess(data) {
  return {
    type: types.ATTENDEE_CSV_DATA,
    payload: {
      attendeeCSVList: data
    }
  }
}

export function updateCSVTableHeaseSuccess(data) {
  return {
    type: types.ATTENDEE_CSV_TABLE_HEADER,
    payload: {
      attendeeCSVTableHeader: data
    }
  }
}

export function getCSVFile(data) {
  return async (dispatch, getState) => {
    try {
      let allAttendeeData = await api.getAttendeeList()
      data = data.map((cur) => {
        const searchItem = allAttendeeData.find((s) => s.id === cur)
        return { ...searchItem }
      })
      return data
    } catch (err) {
      console.log('err', err)
    }
  }
}

export function deleteCheckedInAttendee(deleteId, class_id) {
  return async (dispatch) => {
    try {
      const deleteCheckedAttendee = await api.deleteCheckedAttendee(deleteId, class_id)
      if (deleteCheckedAttendee) {
        dispatch(setSuccessMsg('Attendee Detele Successfully', '/classes/detail'))
      }
    } catch (err) {
      console.log('err', err)
      dispatch(setError('Something went wrong, please try again.'))
    }
  }
}
