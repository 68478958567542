import React from "react";
import MoonLoader from "react-spinners/MoonLoader";

export default function LoadingModal({ color, size, open }) {
  return (
    <>
      {open && (
        <div className="loding_div">
          <MoonLoader color={"#c13e30"} size={100} />
        </div>
      )}
    </>
  );
}
