import './scss/index.scss'
import { useSelector, useDispatch } from 'react-redux'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import ClassesDashboard from './views/classes/ClassesDashboard'
import ClassDetail from './views/classes/ClassDetail'
import ClassCheckin1 from './views/classes/ClassCheckinFirst'
import ClassCheckin2 from './views/classes/ClassCheckinSecond'
import ClassClosePinCheck from './views/classes/ClassClosePinCheck'
import CheckSignUpSheet from './views/classes/CheckSignUpSheet'
import AttendeesDashboard from './views/attendees/AttendeesDashboard'
import TrainingsDashboard from './views/trainings/TrainingsDashboard'
import CompaniesDashboard from './views/companies/CompaniesDashboard'
import HistoryDashboard from './views/history/HistoryDashboard'
import Login from './views/login/Login'
import Portfolio from './views/portfolio/Portfolio'
import ErrorMsgModal from './components/modal/ErrorMsgModal'
import * as types from './store/actionTypes'
import { getUserInfo } from './store/action/userinfo.action'
import { getLoginAuthError } from './store/action/message.action'

function App() {
  const { loginUser } = useSelector((state) => state.userInfo)
  const { msg, isOpenModal, isError, isLogout, redireUrl } = useSelector(
    (state) => state.message
  )
  const dispatch = useDispatch()
  function fetchData() {
    dispatch(getUserInfo())
    if (loginUser.loggedIn === false) {
      dispatch(getLoginAuthError('Please Log In Again', '/'))
    }
  }
  if (!loginUser) {
    fetchData()
  }

  const handlerErrMsgBtnClick = () => {
    dispatch({
      type: types.CLEAR_MSG
    })
    if (redireUrl) {
      window.location.href = redireUrl
    }
  }

  return (
    <Router>
      <div className="container">
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/portfolio" element={<Portfolio />} />
          <Route exact path="/classes" element={<ClassesDashboard />} />
          <Route exact path="/classes/detail" element={<ClassDetail />} />
          <Route exact path="/classes/checkin1" element={<ClassCheckin1 />} />
          <Route exact path="/classes/checkin2" element={<ClassCheckin2 />} />
          <Route exact path="/classes/pincheck" element={<ClassClosePinCheck />} />
          <Route exact path="/classes/signupsheet" element={<CheckSignUpSheet />} />
          <Route exact path="/attendees" element={<AttendeesDashboard />} />
          <Route exact path="/trainings" element={<TrainingsDashboard />} />
          <Route exact path="/companies" element={<CompaniesDashboard />} />
          <Route exact path="/history" element={<HistoryDashboard />} />
        </Routes>
        {msg && (
          <ErrorMsgModal
            open={isOpenModal}
            message={msg}
            isError={isError}
            btnClick={() => handlerErrMsgBtnClick(isLogout)}
          />
        )}
      </div>
    </Router>
  )
}

export default App
