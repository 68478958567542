import React, { useMemo, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ReactTable from './ReactTable'
import { useForm } from 'react-hook-form'
import { DeleteIcon, CloseIcon } from '../icons/icons'
import RedBtn from '../button/RedBtn'
import WhiteBtn from '../button/WhiteBtn'
import MsgModal from '../modal/MsgModal'
import { checkPin } from '../../store/action/userinfo.action'
import { deleteCheckedInAttendee } from '../../store/action/attendee.action'
import ErrorSpan from '../../components/form/ErrorSpan'

export default function CheckedinAttendeeTable() {
  const { classDetail } = useSelector((state) => state.classes)
  const dispatch = useDispatch()
  const [deleteId, setDeleteId] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm()

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, [1000])
  }, [])

  const onSubmit = async (data) => {
    const isPinCorrect = await dispatch(checkPin(data.pin))
    if (isPinCorrect) {
      dispatch(deleteCheckedInAttendee(deleteId, classDetail.id))
      setOpenDeleteModal(false)
    }
  }

  const checkKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      const data = getValues()
      onSubmit(data)
    }
  }

  const deleteClassHandler = (id) => {
    setDeleteId(id)
    setOpenDeleteModal(true)
  }

  const columns = useMemo(
    () => [
      {
        Header: 'First Name',
        accessor: 'firstName',
        width: '15%',
        isSort: true,
        Cell: ({ row }) => {
          return <span>{row.original.firstName}</span>
        }
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        width: '15%',
        isSort: true,
        Cell: ({ row }) => {
          return <span>{row.original.lastName}</span>
        }
      },
      {
        Header: 'Phone',
        accessor: 'phone_number',
        width: '20%',
        isSort: true,
        Cell: ({ row }) => {
          return <span>{row.original.phone_number}</span>
        }
      },
      {
        Header: 'Delete',
        width: '5%',
        isSort: false,
        Cell: ({ row }) => {
          return (
            <span>
              <DeleteIcon size={20} onClick={() => deleteClassHandler(row.original.id)} />
            </span>
          )
        }
      }
    ],
    []
  )
  return (
    <>
      <ReactTable
        columns={columns}
        data={classDetail.checkinList ? classDetail.checkinList : []}
        tableSize={50}
        isShowPagination={false}
        title="Checked-In Attendee"
        isSorted={true}
        tableType="attendees"
        searchBarShow={false}
        isLoading={isLoading}
        setLoading={setLoading}
      />
      {openDeleteModal && (
        <MsgModal open={openDeleteModal}>
          <div className="delete-modal-frame">
            <CloseIcon size="30" onClick={() => setOpenDeleteModal(false)} />
            <h1>Please Enter PIN to Delete</h1>
            <form
              className="modal-form"
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => checkKeyDown(e)}
            >
              <div className="input-frame pin">
                <input
                  type="password"
                  inputMode="numeric"
                  autoComplete="on"
                  maxLength={4}
                  minLength={4}
                  //input number still can type 'e'
                  onKeyDown={(e) =>
                    ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
                  }
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(0, e.target.maxLength)
                  }}
                  {...register('pin', {
                    required: 'This field is required!',
                    minLength: { value: 4, message: 'Minimum 4 numbers' },
                    maxLength: { value: 4, message: 'Maximum 4 numbers' },
                    pattern: { value: /[0-9]*/, message: 'Please Enter Number' }
                  })}
                />
              </div>
              {errors.pin && <ErrorSpan role="alert">{errors.pin.message}</ErrorSpan>}
              <div className="btn-frame">
                <WhiteBtn
                  text="Close"
                  type="cancel"
                  width={150}
                  height={40}
                  marginR={40}
                  close={() => setOpenDeleteModal(false)}
                />
                <RedBtn text="Delete" type="submit" width={150} height={40} />
              </div>
            </form>
          </div>
        </MsgModal>
      )}
    </>
  )
}
