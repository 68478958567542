import axios from 'axios'

export const ClassType = [
  {
    value: '',
    label: 'Class Type'
  },
  {
    value: 'safety',
    label: 'Safety'
  },
  {
    value: 'equipment',
    label: 'Equipment'
  }
  // {
  //   value: "services",
  //   label: "Services",
  // },
]

export const TrainingType = [
  {
    value: '',
    label: 'Training Type'
  },
  {
    value: 'safety',
    label: 'Safety'
  },
  {
    value: 'equipment',
    label: 'Equipment'
  }
  // {
  //   value: "services",
  //   label: "Services",
  // },
]

export const TrainingExpiration = [
  {
    value: '',
    label: 'Expiration'
  },
  {
    value: 12,
    label: '1 Year'
  },
  {
    value: 24,
    label: '2 Year'
  },
  {
    value: 36,
    label: '3 Year'
  }
]
export const Companies = async () => {
  const response = await axios.get('/api/company/list')
  return response.data
}
export const Trainings = async () => {
  const response = await axios.get('/api/training/list')
  return response.data
}

export const ExpireStatus = Object.freeze({
  0: 'N/A',
  12: '1 Year',
  24: '2 Year',
  36: '3 Year'
})

export const ShowStatus = Object.freeze({
  0: 'Good',
  1: '1 Month',
  2: '2 Month',
  3: '3 Month',
  4: 'Expired',
  5: 'Failed'
})

export const StatusColor = Object.freeze({
  0: 'white',
  1: 'darkOrange',
  2: 'orange',
  3: 'yellow',
  4: 'red',
  5: 'red'
})
