import * as types from '../actionTypes'
import * as api from '../../api/classesApi'
import { setError, setSuccessMsg } from './message.action'

export function getAllClasses(searchArgs) {
  return async (dispatch) => {
    try {
      let allClassesData = await api.getClassesList()
      if (searchArgs) {
        allClassesData = Object.keys(searchArgs).reduce(
          (pre, key) => {
            pre = pre.filter((item) => {
              // let exists = Object.values(item).includes(searchArgs);
              if (item.date.toString().includes(searchArgs)) {
                return true
              }
              if (item.ckeck_num.toString().includes(searchArgs)) {
                return true
              }
              if (
                item.name.toLocaleLowerCase().includes(searchArgs.toLocaleLowerCase())
              ) {
                return true
              }
              if (
                item.company.toLocaleLowerCase().includes(searchArgs.toLocaleLowerCase())
              ) {
                return true
              }
              return Object.values(item).includes(searchArgs)
            })
            return pre
          },
          [...allClassesData]
        )
      }
      // return allClassesData;
      dispatch(getallClassesListSuccess(allClassesData))
    } catch (err) {
      console.log('err', err)
      if (err.response.data.message === 'Unauthorized!') {
        dispatch(setError('Please Login Again', '/'))
      }
    }
  }
}
export function searchArgsChange(searchArgs) {
  return {
    type: types.CLASS_ARGS_INUPTCHANGE,
    payload: {
      searchArgs
    }
  }
}

export function getallClassesListSuccess(data) {
  return {
    type: types.CLASSES_LIST,
    payload: {
      allClassesList: data
    }
  }
}

export function getClassDetail(data) {
  return async (dispatch) => {
    try {
      const classDetailData = await api.getClassDetailData(data)
      dispatch(getClassDetailSuccess(classDetailData))
    } catch (err) {
      console.log('err', err)
    }
  }
}

export function getClassDetailSuccess(data) {
  return {
    type: types.CLASS_DETAIL_INFO,
    payload: {
      classDetail: data
    }
  }
}

export function createNewClass(data) {
  return async (dispatch) => {
    try {
      const classCreateData = await api.createNewClassData(data)
      dispatch(getallClassesListSuccess(classCreateData))
      dispatch(setSuccessMsg('Class Created Successfully', 'classes'))
    } catch (err) {
      console.log('err', err)
      dispatch(setError('Something went wrong, please try again'))
    }
  }
}

export function blankClassData() {
  const classData = {
    date: '',
    name: '',
    company: '',
    location: ''
  }
  return {
    type: types.CLASS_BLANK,
    payload: {
      classDetail: classData
    }
  }
}

export function deleteRowClass(data) {
  return async (dispatch) => {
    try {
      const classDeleteData = await api.deleteRowClassData(data)
      dispatch(getallClassesListSuccess(classDeleteData))
      dispatch(setSuccessMsg('Class Deleted Successfully', 'classes'))
    } catch (err) {
      console.log('err', err)
      dispatch(setError('Something went wrong, please try again'))
    }
  }
}

export function updateClass(id, data) {
  return async (dispatch) => {
    try {
      const classUpdateData = await api.updateClassData(id, data)
      dispatch(getallClassesListSuccess(classUpdateData))
      dispatch(setSuccessMsg('Class Updated Successfully', 'classes'))
    } catch (err) {
      console.log('err', err)
      dispatch(setError('Something went wrong, please try again'))
    }
  }
}

export function getSignUpSheet(id, searchArgs) {
  return async (dispatch) => {
    try {
      let signUpSheetData = await api.getSignUpSheetDetail(id)
      if (searchArgs) {
        signUpSheetData = Object.keys(searchArgs).reduce(
          (pre, key) => {
            pre = pre.filter((item) => {
              if (item.ssn.toString().includes(searchArgs)) {
                return true
              }
              if (item.firstName.toLocaleLowerCase().includes(searchArgs)) {
                return true
              }
              if (item.lastName.toLocaleLowerCase().includes(searchArgs)) {
                return true
              }
              if (item.company.toLocaleLowerCase().includes(searchArgs)) {
                return true
              }
              return Object.values(item).includes(searchArgs)
            })
            return pre
          },
          [...signUpSheetData]
        )
      }
      dispatch(getSignUpSheetDetailSuccess(signUpSheetData))
    } catch (err) {
      console.log('err', err)
    }
  }
}

export function getSignUpSheetDetailSuccess(data) {
  return {
    type: types.CLASS_SIGNUPSHEET_DETAIL,
    payload: {
      signUpSheetData: data
    }
  }
}

export function closeClass(class_id, data) {
  return async (dispatch) => {
    try {
      const isClosClass = await api.closeClass(class_id, data)
      if (isClosClass.isClassClosed) {
        //get signupSheet list
        dispatch(getSignUpSheet(class_id))
        dispatch(setSuccessMsg('Class Closed Successfully', '/classes/'))
      }
    } catch (err) {
      console.log('err', err)
      dispatch(setError('Something went wrong, please try again.'))
    }
  }
}
