import * as types from "../actionTypes";

const initState = {
  allCompanyList: [],
  searchArgs: {},
  companyDetail: {},
  companySelectList: [],
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.COMPANY_LIST: {
      return { ...state, allCompanyList: [...action.payload.allCompanyList] };
    }
    case types.COMPANY_ROW_DATA: {
      return { ...state, companyDetail: { ...action.payload.companyDetail } };
    }
    case types.COMPANY_ARGS_INUPTCHANGE: {
      return {
        ...state,
        ...action.payload,
        searchArgs: { ...action.payload.searchArgs },
      };
    }
    case types.COMPANY_BLANK: {
      return { ...state, companyDetail: { ...action.payload.companyDetail } };
    }
    case types.COMPANY_SELECT_LIST: {
      return {
        ...state,
        companySelectList: [...action.payload.companySelectList],
      };
    }
    default:
      return state;
  }
};

export default reducer;
