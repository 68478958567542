import * as types from "../actionTypes";

const initState = {
  allTrainingsList: [],
  searchArgs: {},
  trainingsDetail: {},
  trainingsSelectList: [],
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.TRAININGS_LIST: {
      return {
        ...state,
        allTrainingsList: [...action.payload.allTrainingsList],
      };
    }
    case types.TRAINING_ROW_DATA: {
      return {
        ...state,
        trainingsDetail: { ...action.payload.trainingsDetail },
      };
    }
    case types.TRAINING_ARGS_INUPTCHANGE: {
      return {
        ...state,
        ...action.payload,
        searchArgs: { ...action.payload.searchArgs },
      };
    }
    case types.TRAINING_BLANK: {
      return {
        ...state,
        trainingsDetail: { ...action.payload.trainingsDetail },
      };
    }
    case types.TRAINING_SELECT_LIST: {
      return {
        ...state,
        trainingsSelectList: [...action.payload.trainingsSelectList],
      };
    }
    default:
      return state;
  }
};

export default reducer;
