import React from "react";
import Webcam from "react-webcam";
import WhiteBtn from "../button/WhiteBtn";
import RedBtn from "../button/RedBtn";

export default function Camera({
  picture,
  setPicture,
  setCameraOpen,
  uploadImgFunc,
  setValue,
}) {
  const webcamRef = React.useRef(null);
  const [hasTimeElapsed, setHasTimeElapsed] = React.useState(false);
  const capture = React.useCallback(() => {
    const pictureSrc = webcamRef.current.getScreenshot();
    setPicture(pictureSrc);
    if (setValue) {
      setValue("picture", pictureSrc);
      setValue("profile_image", pictureSrc);
    }
  }, []);
  setTimeout(() => {
    setHasTimeElapsed(true);
  }, 1000);
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user",
  };

  return (
    <div className="camera-layout">
      <div className="camera-frame">
        {picture === "" ? (
          <Webcam
            audio={false}
            height={"100%"}
            ref={webcamRef}
            width={"100%"}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
          />
        ) : (
          <img src={picture} alt="" />
        )}
        {hasTimeElapsed && (
          <div className="btns-frame">
            {picture !== '' ? (
              <RedBtn
                text="Upload"
                type="submit"
                onClickFunc={(e) => {
                  e.preventDefault()
                  uploadImgFunc()
                }}
              />
            ) : (
              <>
                <RedBtn
                  text="Capture"
                  type="submit"
                  onClickFunc={(e) => {
                    e.preventDefault()
                    capture()
                  }}
                />
              </>
            )}
          </div>
        )}
      </div>
      {hasTimeElapsed && (
        <div className="btn-frame">
          {picture !== "" ? (
            <div className="btn-frame">
              <WhiteBtn
                text="Retake"
                type="cancel"
                width={100}
                height={40}
                marginR={30}
                close={(e) => {
                  e.preventDefault();
                  setPicture("")
                }}
              />
              <WhiteBtn
                text="Close"
                type="cancel"
                width={100}
                height={40}
                close={(e) => {
                  e.preventDefault();
                  setCameraOpen(false);
                }}
              />
            </div>
          ) : (
            <>
              <WhiteBtn
                text="Close"
                type="cancel"
                width={100}
                height={40}
                close={(e) => {
                  e.preventDefault();
                  setCameraOpen(false);
                }}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
}
