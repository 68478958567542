import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../../components/layout/Layout'
import PortfolioPinCheck from './PortfolioPinCheck'
import SubMenuBtn from '../../components/button/SubMenuBtn'
import { getUserInfo } from '../../store/action/userinfo.action'
import Modal from '../../components/modal/Modal'
import UpdatePinForm from '../../components/form/UpdatePinForm'
import ChangeEmailForm from '../../components/form/ChangeEmailForm'
import ChangePasswordForm from '../../components/form/ChangePasswordForm'
import ChangeUsernameForm from '../../components/form/ChangeUsernameForm'
import { checkPin } from '../../store/action/userinfo.action'

export default function Portfolio() {
  const { loginUser } = useSelector((state) => state.userInfo)
  const [isPinPass, setPinPass] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [title, setTitle] = useState('')
  const [formType, setFormType] = useState('')
  const dispatch = useDispatch()

  const checkPinCorrect = async (data) => {
    const isPinCorrect = await dispatch(checkPin(data.pin))
    if (isPinCorrect) {
      setPinPass(true)
    }
  }

  useEffect(() => {
    dispatch(getUserInfo())
  }, [dispatch])

  const updatePinFun = () => {
    setTitle('Update PIN')
    setFormType('pin')
    setOpenModal(true)
  }
  const changePwdFun = () => {
    setTitle('Change Password')
    setFormType('password')
    setOpenModal(true)
  }
  const changeUsernameFun = () => {
    setTitle('Change Username')
    setFormType('username')
    setOpenModal(true)
  }
  const changeEmailFun = () => {
    setTitle('Change Email')
    setFormType('email')
    setOpenModal(true)
  }

  const FormSwitch = () => {
    switch (formType) {
      case 'password':
        return <ChangePasswordForm setOpenModal={setOpenModal} />
      case 'username':
        return <ChangeUsernameForm setOpenModal={setOpenModal} />
      case 'email':
        return <ChangeEmailForm setOpenModal={setOpenModal} />
      default:
        return <UpdatePinForm setOpenModal={setOpenModal} />
    }
  }
  return (
    <>
      <Layout title="Portfolio" mainPage="portfolio" noMenu={true}>
        <div className="portfolio-frame">
          {!isPinPass ? (
            <PortfolioPinCheck checkPinCorrect={checkPinCorrect} />
          ) : (
            <div className="portfolio-main">
              <h2>Username: {loginUser.username}</h2>
              <h2 className="end">Email: {loginUser.email}</h2>
              <SubMenuBtn title="Update PIN" onClick={updatePinFun} />
              <SubMenuBtn title="Change Password" onClick={changePwdFun} />
              <SubMenuBtn title="Change Username" onClick={changeUsernameFun} />
              <SubMenuBtn title="Change Email" onClick={changeEmailFun} />
            </div>
          )}
        </div>
      </Layout>
      {openModal && (
        <Modal
          open={openModal}
          close={() => setOpenModal(false)}
          title={title}
          wSize={80}
          hSize={80}
        >
          {FormSwitch()}
        </Modal>
      )}
    </>
  )
}
