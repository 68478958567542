import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import RedBtn from "../../components/button/RedBtn";
import WhiteBtn from "../../components/button/WhiteBtn";
import ErrorSpan from "../../components/form/ErrorSpan";
import { setNewEmail } from "../../store/action/userinfo.action";

export default function ChangeEmailForm({ setOpenModal }) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const email = useRef({});
  email.current = watch("new_email", "");
  const onSubmit = (data) => {
    dispatch(setNewEmail(data));
    setOpenModal(false);

  };
  const checkKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const data = getValues();
      onSubmit(data);
      setOpenModal(false);
    }
  };
  return (
    <form
      className="modal-form"
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e) => checkKeyDown(e)}
    >
      <div className="input-frame pin">
        <input
          placeholder="Please Enter Old Email"
          {...register('old_email', {
            required: 'This field is required!',
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: 'Invalid email address'
            }
          })}
        />
      </div>
      {errors.old_email && <ErrorSpan role="alert">{errors.old_email.message}</ErrorSpan>}
      <div className="input-frame pin">
        <input
          placeholder="Please Enter New Email"
          {...register('new_email', {
            required: 'This field is required!',
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: 'Invalid email address'
            }
          })}
        />
      </div>
      {errors.new_email && <ErrorSpan role="alert">{errors.new_email.message}</ErrorSpan>}
      <div className="input-frame pin">
        <input
          placeholder="Comfirm New Email"
          {...register('confirm_email', {
            required: 'This field is required!',
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: 'Invalid email address'
            },
            validate: (value) => value === email.current || 'The email do not match'
          })}
        />
      </div>
      {errors.confirm_email && <ErrorSpan>{errors.confirm_email.message}</ErrorSpan>}
      <div className="btn-frame">
        <WhiteBtn
          text="Close"
          type="cancel"
          width={150}
          height={40}
          marginR={40}
          close={() => setOpenModal(false)}
        />
        <RedBtn text="Submit" type="submit" width={150} height={40} />
      </div>
    </form>
  )
}
