import axios from 'axios'

export const getCompanyList = async () => {
  const response = await axios.get('/api/company/all')
  return response.data
}

export const getRowCompanyData = async (id) => {
  const response = await axios.post('/api/company', { id })
  return response.data
}

export const getCompanySelectData = async () => {
  const response = await axios.get('/api/company/list')
  return response.data
}

export const createNewCompanyData = async (data) => {
  await axios.post('/api/company/create', {
    name: data.company_name,
    admin_email: data.admin_email,
    admin_first_name: data.admin_first_name,
    admin_last_name: data.admin_last_name,
    city_state: data.location
  })

  const response = await axios.get('/api/company/all')
  return response.data
}

export const updateCompanyData = async (companyId, data) => {
  await axios.put('/api/company/update', {
    id: companyId,
    data: {
      name: data.company_name,
      admin_email: data.email,
      admin_first_name: data.admin_first_name,
      admin_last_name: data.admin_last_name,
      city_state: data.location
    }
  })

  const response = await axios.get('/api/company/all')
  return response.data
}

export const deleteRowCompanyData = async (id) => {
  await axios.put('/api/company/delete', { id })

  const response = await axios.get('/api/company/all')
  return response.data
}
