import {
  BiError,
  BiSearch,
  BiCamera,
  BiCommentError,
  BiMessageCheck,
  BiCheck,
  BiX
} from 'react-icons/bi'
import { MdModeEditOutline, MdKeyboardBackspace } from 'react-icons/md'
import {
  RiCloseCircleFill,
  RiDeleteBin6Fill,
  RiFileTextLine,
  RiPrinterFill,
  RiMailSendFill
} from 'react-icons/ri'
import { HiEye, HiEyeOff } from 'react-icons/hi'
import { FaRegCalendarAlt } from 'react-icons/fa'
import {
  MdOutlineClose,
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp
} from 'react-icons/md'
import { GoPlus } from 'react-icons/go'

export const ErrorIcon = (props) => (
  <i className="icons">
    <BiError {...props} />
  </i>
)

export const BackIcon = ({ props }) => (
  <i className="icons">
    <MdKeyboardBackspace {...props} />
  </i>
)

export const CalendarIcon = (props) => (
  <i className="icons calendar-icon">
    <FaRegCalendarAlt {...props} />
  </i>
)

export const ErrorMsgIcon = ({ size, ...props }) => (
  <i className="icons msg">
    <BiCommentError {...props} style={{ width: `${size}px`, height: `${size}px` }} />
  </i>
)

export const SuccessMsgIcon = ({ size, ...props }) => (
  <i className="icons msg">
    <BiMessageCheck
      {...props}
      style={{ width: `${size}px`, height: `${size}px` }}
      // style={{ width: `${size}px`, height: `${size}px`, color: '#02bb72' }}
    />
  </i>
)

export const ClearIcon = ({ clear, className, size, name, ...props }) => (
  <i
    className={['icons', className].join(' ')}
    onClick={clear}
    style={{
      cursor: 'pointer'
    }}
    name={name}
  >
    <RiCloseCircleFill {...props} style={{ width: `${size}px`, height: `${size}px` }} />
  </i>
)

export const EyeOpenIcon = ({ openCloseEye, className, size, name, ...props }) => (
  <i
    className={['icons', className].join(' ')}
    onClick={openCloseEye}
    style={{
      cursor: 'pointer'
    }}
    name={name}
  >
    <HiEye {...props} style={{ width: `${size}px`, height: `${size}px` }} />
  </i>
)

export const EyeCloseIcon = ({ openCloseEye, className, size, name, ...props }) => (
  <i
    className={['icons', className].join(' ')}
    onClick={openCloseEye}
    style={{
      cursor: 'pointer'
    }}
    name={name}
  >
    <HiEyeOff {...props} style={{ width: `${size}px`, height: `${size}px` }} />
  </i>
)

export const CloseIcon = ({ close, className, size, ...props }) => (
  <i
    className={['icons close-icon', className].join(' ')}
    onClick={close}
    style={{
      cursor: 'pointer'
    }}
  >
    <MdOutlineClose {...props} style={{ width: `${size}px`, height: `${size}px` }} />
  </i>
)

export const CreateIcon = ({ open, className, size, ...props }) => (
  <i
    className={['icons', className].join(' ')}
    onClick={open}
    style={{
      cursor: 'pointer'
    }}
  >
    <GoPlus {...props} style={{ width: `${size}px`, height: `${size}px` }} />
  </i>
)

export const AscIcon = ({ sort, className, size, ...props }) => (
  <i
    className={['icons', className].join(' ')}
    onClick={sort}
    style={{
      cursor: 'pointer'
    }}
  >
    <MdOutlineKeyboardArrowUp
      {...props}
      style={{ width: `${size}px`, height: `${size}px` }}
    />
  </i>
)

export const DescIcon = ({ sort, className, size, ...props }) => (
  <i
    className={['icons', className].join(' ')}
    onClick={sort}
    style={{
      cursor: 'pointer',
      width: `${size}px`,
      height: `${size}px`
    }}
  >
    <MdOutlineKeyboardArrowDown
      {...props}
      style={{ width: `${size}px`, height: `${size}px` }}
    />
  </i>
)

export const SearchIcon = ({ search, className, size, ...props }) => (
  <i
    className={['icons', className].join(' ')}
    onClick={search}
    style={{
      cursor: 'pointer'
    }}
  >
    <BiSearch {...props} style={{ width: `${size}px`, height: `${size}px` }} />
  </i>
)

export const DeleteIcon = ({ deleteFunc, className, size, ...props }) => (
  <i
    className={['icons', className].join(' ')}
    onClick={deleteFunc}
    style={{
      cursor: 'pointer'
    }}
  >
    <RiDeleteBin6Fill {...props} style={{ width: `${size}px`, height: `${size}px` }} />
  </i>
)

export const EditIcon = ({ editFunc, className, size, ...props }) => (
  <i
    className={['icons', className].join(' ')}
    onClick={editFunc}
    style={{
      cursor: 'pointer'
    }}
  >
    <MdModeEditOutline {...props} style={{ width: `${size}px`, height: `${size}px` }} />
  </i>
)

export const FileIcon = ({ openFile, className, size, ...props }) => (
  <i
    className={['icons', className].join(' ')}
    onClick={openFile}
    style={{
      cursor: 'pointer'
    }}
  >
    <RiFileTextLine {...props} style={{ width: `${size}px`, height: `${size}px` }} />
  </i>
)

export const CameraIcon = ({ openCam, className, size, ...props }) => (
  <i
    className={['icons', className].join(' ')}
    onClick={openCam}
    style={{
      cursor: 'pointer'
    }}
  >
    <BiCamera {...props} style={{ width: `${size}px`, height: `${size}px` }} />
  </i>
)

export const PrinterIcon = ({ openCam, className, size, ...props }) => (
  <i
    className={['icons', className].join(' ')}
    onClick={openCam}
    style={{
      cursor: 'pointer'
    }}
  >
    <RiPrinterFill {...props} style={{ width: `${size}px`, height: `${size}px` }} />
  </i>
)

export const EmailSentIcon = ({ openCam, className, size, ...props }) => (
  <i
    className={['icons', className].join(' ')}
    onClick={openCam}
    style={{
      cursor: 'pointer'
    }}
  >
    <RiMailSendFill {...props} style={{ width: `${size}px`, height: `${size}px` }} />
  </i>
)

export const CheckIcon = ({ size, ...props }) => (
  <i className="icons check">
    <BiCheck {...props} style={{ width: `${size}px`, height: `${size}px` }} />
  </i>
)

export const CrossIcon = ({ size, ...props }) => (
  <i className="icons cross">
    <BiX {...props} style={{ width: `${size}px`, height: `${size}px` }} />
  </i>
)
