import React from 'react'
import { useDispatch } from 'react-redux'
import logo from './../../images/echo8safety-logo.svg'
import { getUserLogout } from '../../store/action/userinfo.action'
import { Link, useNavigate } from 'react-router-dom'

function Header({ mainPage, currentUser }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const logout = (e) => {
    e.preventDefault()
    dispatch(getUserLogout(currentUser.currentUser))
    currentUser.setCurrentUser(null)
    navigate('/')
  }

  return (
    <nav>
      <img src={logo} alt="Echo8Safety-Logo" />
      <ul className="menu">
        <li
          className={['meun-item portfolio', mainPage === 'portfolio' ? 'here' : ''].join(
            ' '
          )}
        >
          <Link to="/portfolio">
            <p>Portfolio</p>
          </Link>
        </li>
        <li
          className={['meun-item dashboard', mainPage === 'dashboard' ? 'here' : ''].join(
            ' '
          )}
        >
          <Link to="/classes">
            <p>Dashboard</p>
          </Link>
        </li>
        <li className="meun-item logout">
          <Link to="#" onClick={logout}>
            <p>Log Out</p>
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default Header
