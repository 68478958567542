import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import RedBtn from "../../components/button/RedBtn";
import WhiteBtn from "../../components/button/WhiteBtn";
import ErrorSpan from "../../components/form/ErrorSpan";
import { setNewUsername } from "../../store/action/userinfo.action";

export default function ChangeUsernameForm({ setOpenModal }) {
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors }
  } = useForm()
  const username = useRef({})
  username.current = watch('new_username', '')
  const onSubmit = (data) => {
    dispatch(setNewUsername(data))
    setOpenModal(false)
  }
  const checkKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      const data = getValues()
      onSubmit(data)
      setOpenModal(false)

    }
  }
  return (
    <form
      className="modal-form"
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e) => checkKeyDown(e)}
    >
      <div className="input-frame pin">
        <input
          placeholder="Please Enter Old Username"
          {...register('old_username', {
            required: true
          })}
        />
      </div>
      {errors.old_username ? <ErrorSpan>This field is required!</ErrorSpan> : ''}

      <div className="input-frame pin">
        <input
          placeholder="Please Enter New Username"
          {...register('new_username', {
            required: true
          })}
        />
      </div>
      {errors.new_username ? <ErrorSpan>This field is required!</ErrorSpan> : ''}
      <div className="input-frame pin">
        <input
          placeholder="Comfirm New Username"
          {...register('confirm_username', {
            required: 'This field is required!',
            validate: (value) => value === username.current || 'The email do not match'
          })}
        />
      </div>
      {errors.confirm_username && (
        <ErrorSpan>{errors.confirm_username.message}</ErrorSpan>
      )}
      <div className="btn-frame">
        <WhiteBtn
          text="Close"
          type="cancel"
          width={150}
          height={40}
          marginR={40}
          close={() => setOpenModal(false)}
        />
        <RedBtn text="Submit" type="submit" width={150} height={40} />
      </div>
    </form>
  )
}
