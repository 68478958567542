import React, { useMemo, useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../../components/layout/Layout'
import ReactTable from '../../components/table/ReactTable'
import { FileIcon, CheckIcon, CrossIcon } from '../../components/icons/icons'
import {
  getAllHistory,
  getClassHistory,
  saveSignUpSheetID
} from '../../store/action/history.action'
import Modal from '../../components/modal/Modal'
import CerfiticationTemplate from './ClassCertification'
import AttenndeeCertiNumAddTable from '../../components/table/AttenndeeCertiNumAddTable'

export default function HistoryDashboard() {
  const { allHistoryList, classHistorydata, classAttendeesHistorydata } = useSelector(
    (state) => state.history
  )
  const [openFileModal, setOpeFilenModal] = useState(false)
  const [openRowModal, setOpeRowModal] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [class_id, setClassId] = useState(false)

  const dispatch = useDispatch()
  useEffect(() => {
    setLoading(true)
    dispatch(getAllHistory())
    if (allHistoryList) {
      setTimeout(() => {
        setLoading(false)
      }, [500])
    }
  }, [dispatch])

  const searchHandler = (e) => {
    dispatch(getAllHistory(e.target.value))
  }
  const openFileFun = useCallback(
    (id) => {
      dispatch(saveSignUpSheetID(id))
      setOpeFilenModal(true)
    },
    [dispatch]
  )

  const addCertifiNumTable = useCallback(
    (id) => {
      setClassId(id)
      dispatch(getClassHistory(id))
      if (classAttendeesHistorydata) {
        setOpeRowModal(true)
      }
    },
    [dispatch, classAttendeesHistorydata]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date',
        width: '20%',
        isSort: true,
        Cell: ({ row }) => {
          return (
            <span
              style={row.original.cert_number ? {} : { color: 'white', cursor: 'auto' }}
              onClick={() =>
                row.original.cert_number ? addCertifiNumTable(row.original.id) : null
              }
            >
              {row.original.date}
            </span>
          )
        }
      },
      {
        Header: 'Class Name',
        accessor: 'className',
        width: '20%',
        isSort: true,
        Cell: ({ row }) => {
          return (
            <span
              style={row.original.cert_number ? {} : { color: 'white', cursor: 'auto' }}
              onClick={() =>
                row.original.cert_number ? addCertifiNumTable(row.original.id) : null
              }
            >
              {row.original.className}
            </span>
          )
        }
      },
      {
        Header: 'Attendees Num',
        accessor: 'attendeesNum',
        width: '20%',
        isSort: true,
        Cell: ({ row }) => {
          return (
            <span
              style={row.original.cert_number ? {} : { color: 'white', cursor: 'auto' }}
              onClick={() =>
                row.original.cert_number ? addCertifiNumTable(row.original.id) : null
              }
            >
              {row.original.attendeesNum}
            </span>
          )
        }
      },
      {
        Header: 'Certi Num Req',
        accessor: 'cert_number',
        width: '10%',
        isSort: true,
        Cell: ({ row }) => {
          return row.original.cert_number ? (
            <span>
              <CheckIcon size={20} />
            </span>
          ) : (
            <span>
              <CrossIcon size={20} />
            </span>
          )
        }
      },
      {
        Header: 'File',
        width: '10%',
        isSort: false,
        Cell: ({ row }) => {
          return (
            <span>
              <FileIcon size={20} openFile={() => openFileFun(row.original.id)} />
            </span>
          )
        }
      }
    ],
    [addCertifiNumTable, openFileFun]
  )
  return (
    <>
      <Layout mainPage="dashboard" page="history" title="Dashboard" isCreateBtn={false}>
        <ReactTable
          columns={columns}
          data={allHistoryList}
          tableSize={5}
          isShowPagination={true}
          isRowClickable={true}
          title="History"
          isSorted={true}
          tableType="history"
          searchHandler={searchHandler}
          searchBarShow={true}
          isLoading={isLoading}
        />
      </Layout>
      {openFileModal && (
        <Modal
          open={openFileModal}
          close={() => setOpeFilenModal(false)}
          title="Class SignUp Sheet"
          wSize={80}
          hSize={80}
        >
          <CerfiticationTemplate setOpenModal={setOpeFilenModal} />
        </Modal>
      )}

      {openRowModal && (
        <Modal
          open={openRowModal}
          close={() => setOpeRowModal(false)}
          title={classHistorydata.className}
          wSize={80}
          hSize={80}
        >
          <AttenndeeCertiNumAddTable class_id={class_id} />
        </Modal>
      )}
    </>
  )
}
