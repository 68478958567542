import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../../components/layout/Layout'
import ReactTable from '../../components/table/ReactTable'
import {
  EditIcon,
  DeleteIcon,
  CloseIcon,
  CheckIcon,
  CrossIcon
} from '../../components/icons/icons'
import Modal from '../../components/modal/Modal'
import MsgModal from '../../components/modal/MsgModal'
import TrainingsCreateEditForm from '../../components/form/TrainingsCreateEditForm'
import RedBtn from '../../components/button/RedBtn'
import WhiteBtn from '../../components/button/WhiteBtn'
import { ExpireStatus } from '../../utils/enums'
import {
  getAllTrainings,
  getRowTraining,
  blankTrainingData,
  deleteRowTraining
} from '../../store/action/trainings.action'

export default function TrainingsDashboard() {
  const { allTrainingsList } = useSelector((state) => state.trainings)
  const [openModal, setOpenModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [modaltype, setmodaltype] = useState('')
  const [deleteId, setDeleteId] = useState('')
  const [isLoading, setLoading] = useState(true)

  const dispatch = useDispatch()

  useEffect(() => {
    setLoading(true)
    dispatch(getAllTrainings())
    if (allTrainingsList) {
      setTimeout(() => {
        setLoading(false)
      }, [500])
    }
  }, [dispatch])

  const editRowFunc = useCallback(
    (data) => {
      setmodaltype('edit')
      dispatch(getRowTraining(data))
      setOpenModal(true)
    },
    [dispatch]
  )

  const addTrainingHandler = () => {
    setmodaltype('create')
    dispatch(blankTrainingData())
    setOpenModal(true)
  }

  const deleteTrainingHandler = (id) => {
    setDeleteId(id)
    setOpenDeleteModal(true)
  }

  const deleteRowFunc = useCallback(() => {
    dispatch(deleteRowTraining(deleteId))
    setOpenDeleteModal(false)
  }, [dispatch, deleteId])

  const searchHandler = (e) => {
    dispatch(getAllTrainings(e.target.value))
  }
  const columns = useMemo(
    () => [
      {
        Header: 'Training Title',
        accessor: 'training_name',
        width: '20%',
        isSort: true,
        Cell: ({ row }) => {
          return <span>{row.original.training_name}</span>
        }
      },
      {
        Header: 'Type',
        accessor: 'type',
        width: '10%',
        isSort: true,
        Cell: ({ row }) => {
          return <span>{row.original.type}</span>
        }
      },
      {
        Header: 'Certi Num Req',
        accessor: 'certiNumRequred',
        width: '10%',
        isSort: true,
        Cell: ({ row }) => {
          return row.original.cert_number ? (
            <CheckIcon size={20} />
          ) : (
            <CrossIcon size={20} />
          )
        }
      },
      {
        Header: 'Expiration',
        accessor: 'expiration',
        width: '10%',
        isSort: true,
        Cell: ({ row }) => {
          return <span>{ExpireStatus[row.original.expiration]}</span>
        }
      },
      {
        Header: 'Edit',
        width: '5%',
        isSort: false,
        Cell: ({ row }) => {
          return (
            <span>
              <EditIcon size={20} onClick={() => editRowFunc(row.original.id)} />
            </span>
          )
        }
      },
      {
        Header: 'Delete',
        width: '5%',
        isSort: false,
        Cell: ({ row }) => {
          return (
            <span>
              <DeleteIcon
                size={20}
                onClick={() => deleteTrainingHandler(row.original.id)}
              />
            </span>
          )
        }
      }
    ],
    [editRowFunc]
  )
  return (
    <>
      <Layout
        mainPage="dashboard"
        page="trainings"
        title="Dashboard"
        isCreateBtn={true}
        modaltype={modaltype}
        open={addTrainingHandler}
      >
        <ReactTable
          columns={columns}
          data={allTrainingsList}
          tableSize={5}
          isShowPagination={true}
          title="Trainings/Services"
          isSorted={true}
          tableType="trainings"
          searchHandler={searchHandler}
          searchBarShow={true}
          isLoading={isLoading}
        />
      </Layout>
      {openModal && (
        <Modal
          open={openModal}
          close={() => setOpenModal(false)}
          title={
            modaltype === 'edit' ? 'Edit Training Detail' : 'Create New Training Event'
          }
          wSize={80}
          hSize={80}
        >
          <TrainingsCreateEditForm
            close={() => setOpenModal(false)}
            modaltype={modaltype}
            setOpenModal={setOpenModal}
          />
        </Modal>
      )}
      {openDeleteModal && (
        <MsgModal open={openDeleteModal}>
          <div className="delete-modal-frame">
            <CloseIcon size="30" onClick={() => setOpenDeleteModal(false)} />
            <h1>Are You Sure To Delete?</h1>
            <div className="btn-frame">
              <WhiteBtn
                text="Close"
                type="cancel"
                width={150}
                height={40}
                marginR={40}
                close={() => setOpenDeleteModal(false)}
              />
              <RedBtn
                text="Submit"
                type="submit"
                width={150}
                height={40}
                onClickFunc={deleteRowFunc}
              />
            </div>
          </div>
        </MsgModal>
      )}
    </>
  )
}
