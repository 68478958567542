import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import ErrorSpan from './ErrorSpan'
import 'react-datepicker/dist/react-datepicker.css'
import RedBtn from '../button/RedBtn'
import WhiteBtn from '../button/WhiteBtn'
import { ClearIcon } from '../icons/icons'
import { updateAttendeeDetail } from '../../store/action/attendee.action'
import FormSelect from '../modal/FormSelect'
import { getCompanySelectList } from '../../store/action/companies.action'

export default function AttendeeDetailEditForm({
  setEditAttendeeMode,
  editAttendeeMode
}) {
  const { attendeeDetail } = useSelector((state) => state.attendee)
  const { companySelectList } = useSelector((state) => state.companies)
  const [showEmailClear, setShowEmailClear] = useState(false)
  const [showPhoneClear, setShowPhoneClear] = useState(false)
  const dispatch = useDispatch()

  const {
    register,
    watch,
    handleSubmit,
    resetField,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({ defaultValues: { ...attendeeDetail }, mode: 'onTouched' })
  const watchAllFields = watch()
  useEffect(() => {
    reset({
      ...attendeeDetail
    })
  }, [attendeeDetail, reset])

  useEffect(() => {
    dispatch(getCompanySelectList())
  }, [])

  useEffect(() => {
    if (!watchAllFields.email) {
      setShowEmailClear(false)
    } else {
      setShowEmailClear(true)
    }
    if (!watchAllFields.phone_number) {
      setShowPhoneClear(false)
    } else {
      setShowPhoneClear(true)
    }
  }, [watchAllFields])

  const clearFunc = (type) => {
    resetField('email')
    resetField('phone_number')
  }
  const onSubmit = (data) => {
    dispatch(updateAttendeeDetail(attendeeDetail.id, data))
  }
  const checkKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      const data = getValues()
      onSubmit(data)
    }
  }
  const compantChangeFunc = (e) => {
    setValue('company_id', e.target.value)
  }
  return (
    <form
      className="modal-form"
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e) => checkKeyDown(e)}
    >
      <div className="input-frame">
        <input
          placeholder="Attendee Email"
          type="email"
          {...register('email', {
            required: 'This field is required!',
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: 'Invalid email address'
            }
          })}
        />
        {showEmailClear && (
          <div className="icon-frame">
            <ClearIcon size="20" clear={() => clearFunc('email')} />
          </div>
        )}
      </div>
      {errors.email && <ErrorSpan role="alert">{errors.email.message}</ErrorSpan>}
      <div className="input-frame">
        <input
          placeholder="Attendee Phone Number"
          type="number"
          inputMode="numeric"
          maxLength={10}
          minLength={10}
          //input number still can type 'e'
          onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
          onInput={(e) => {
            if (e.target.value.length > e.target.maxLength)
              e.target.value = e.target.value.slice(0, e.target.maxLength)
          }}
          {...register('phone_number', {
            minLength: { value: 10, message: 'Minimum 10 numbers' },
            maxLength: { value: 10, message: 'Maximum 10 numbers' },
            pattern: {
              value: /^(0|[1-9]\d*)(\.\d+)?$/,
              message: 'Please Enter Number'
            }
          })}
        />
        {showPhoneClear && (
          <div className="icon-frame">
            <ClearIcon size="20" clear={() => clearFunc('phone_number')} />
          </div>
        )}
      </div>
      {errors.phone_number && (
        <ErrorSpan role="alert">{errors.phone_number.message}</ErrorSpan>
      )}
      <div className="input-frame">
        <FormSelect
          name="company_id"
          options={companySelectList}
          control={control}
          change={compantChangeFunc}
        />
      </div>
      {errors.company_id ? <ErrorSpan>This field is required!</ErrorSpan> : ''}

      <div className="btn-frame">
        <WhiteBtn
          text="Cancel"
          type="cancel"
          close={() => setEditAttendeeMode(false)}
          width={200}
          height={50}
          marginR={50}
        />
        <RedBtn text="submit" type="submit" width={200} height={50} />
      </div>
    </form>
  )
}
