import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import WhiteBtn from '../../components/button/WhiteBtn'
import RedBtn from '../../components/button/RedBtn'
import ErrorSpan from '../../components/form/ErrorSpan'
import { CloseIcon } from '../../components/icons/icons'
import MsgModal from '../../components/modal/MsgModal'
import { checkPin } from '../../store/action/userinfo.action'
import { getClassDetail } from '../../store/action/classes.action'
import CheckedinAttendeeTable from '../../components/table/CheckedinAttendeeTable'

export default function ClassDetail() {
  const { classDetail } = useSelector((state) => state.classes)
  const [openModal, setOpenModal] = useState(false)
  const navigate = useNavigate()
  const handleClick = () => navigate('/classes/checkin1')
  const dispatch = useDispatch()
  const backFunc = () => {
    setOpenModal(true)
  }
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm()

  useEffect(() => {
    const class_id = localStorage.getItem('classId')
    if (classDetail.id) {
      dispatch(getClassDetail(classDetail.id))
    } else if (class_id) {
      dispatch(getClassDetail(class_id))
    }
  }, [])

  const onSubmit = async (data) => {
    const isPinCorrect = await dispatch(checkPin(data.pin))
    if (isPinCorrect) {
      navigate('/classes')
      window.location.reload()
    }
  }
  const checkKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      const data = getValues()
      onSubmit(data)
    }
  }
  return (
    <>
      <div className="detail-frame">
        <CloseIcon size={35} close={backFunc} />
        <div className="detail-title">
          <h1>{classDetail.name}</h1>
          <h1>{classDetail.date}</h1>
        </div>
        <div className="main-block">
          {/* <div className="left"> */}
          <div className="detail-info">
            <h3>Location: {classDetail.location}</h3>
            <h3>Checked in: {classDetail.ckeck_num}</h3>
          </div>
          {/* </div> */}
          {/* <div className="right"> */}
          <CheckedinAttendeeTable />
          {/* </div> */}
        </div>
        <div className="btn-frame">
          <WhiteBtn
            text="Submit Class"
            width={250}
            height={50}
            path="/classes/pincheck"
          />
          <RedBtn text="Check In" width={250} height={50} onClickFunc={handleClick} />
        </div>
      </div>
      <MsgModal open={openModal}>
        <div className="delete-modal-frame">
          <CloseIcon size="30" onClick={() => setOpenModal(false)} />
          <h1>Please Enter PIN</h1>
          <form
            className="modal-form"
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => checkKeyDown(e)}
          >
            <div className="input-frame pin">
              <input
                type="password"
                inputMode="numeric"
                placeholder="PIN"
                autoComplete="on"
                maxLength={4}
                minLength={4}
                //input number still can type 'e'
                onKeyDown={(e) =>
                  ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
                }
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(0, e.target.maxLength)
                }}
                {...register('pin', {
                  required: 'This field is required!',
                  minLength: { value: 4, message: 'Minimum 4 numbers' },
                  maxLength: { value: 4, message: 'Maximum 4 numbers' },
                  pattern: {
                    value: /^(0|[1-9]\d*)(\.\d+)?$/,
                    message: 'Please Enter Number'
                  }
                })}
              />
            </div>
            {errors.pin && <ErrorSpan role="alert">{errors.pin.message}</ErrorSpan>}
            <div className="btn-frame">
              <WhiteBtn
                text="Close"
                type="cancel"
                width={150}
                height={40}
                marginR={40}
                close={() => setOpenModal(false)}
              />
              <RedBtn text="Submit" type="submit" width={150} height={40} />
            </div>
          </form>
        </div>
      </MsgModal>
    </>
  )
}
