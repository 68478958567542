import * as types from '../actionTypes'
import * as api from '../../api/companyApi'
import { setError, setSuccessMsg } from './message.action'

export function getAllCompany(searchArgs) {
  return async (dispatch) => {
    try {
      let allCompanyData = await api.getCompanyList()
      if (searchArgs) {
        allCompanyData = Object.keys(searchArgs).reduce(
          (pre, key) => {
            pre = pre.filter((item) => {
              if (
                item.admin_name
                  .toLocaleLowerCase()
                  .includes(searchArgs.toLocaleLowerCase())
              ) {
                return true
              }
              if (
                item.company_name
                  .toLocaleLowerCase()
                  .includes(searchArgs.toLocaleLowerCase())
              ) {
                return true
              }
              if (
                item.admin_email
                  .toLocaleLowerCase()
                  .includes(searchArgs.toLocaleLowerCase())
              ) {
                return true
              }
              return Object.values(item).includes(searchArgs)
            })
            return pre
          },
          [...allCompanyData]
        )
      }
      dispatch(getAllConpanyListSuccess(allCompanyData))
    } catch (err) {
      console.log('err', err)
      if (err.response.data.message === 'Unauthorized!') {
        dispatch(setError('Please Login Again', '/'))
      }
    }
  }
}
export function searchArgsChange(searchArgs) {
  return {
    type: types.COMPANY_ARGS_INUPTCHANGE,
    payload: {
      searchArgs
    }
  }
}

export function getAllConpanyListSuccess(data) {
  return {
    type: types.COMPANY_LIST,
    payload: {
      allCompanyList: data
    }
  }
}

export function blankCompanyData() {
  const companyData = {
    company_name: '',
    email: '',
    phone: ''
  }
  return {
    type: types.COMPANY_BLANK,
    payload: {
      companyDetail: companyData
    }
  }
}

export function getRowCompany(data) {
  return async (dispatch) => {
    try {
      const companyDetailData = await api.getRowCompanyData(data)
      dispatch(getRowCompanySuccess(companyDetailData))
    } catch (err) {
      console.log('err', err)
    }
  }
}

export function getRowCompanySuccess(data) {
  return {
    type: types.COMPANY_ROW_DATA,
    payload: {
      companyDetail: data
    }
  }
}

export function deleteRowCompany(data) {
  return async (dispatch) => {
    try {
      const companyDeleteData = await api.deleteRowCompanyData(data)
      dispatch(getAllConpanyListSuccess(companyDeleteData))
      dispatch(setSuccessMsg('Company Deleted Successfully!', '/companies'))
    } catch (err) {
      console.log('err', err)
      dispatch(setError('Something went wrong, please try again'))
    }
  }
}

export function createNewCompany(data) {
  return async (dispatch) => {
    try {
      const companyCreateData = await api.createNewCompanyData(data)
      dispatch(getAllConpanyListSuccess(companyCreateData))
      dispatch(setSuccessMsg('Company Created Successfully!', '/companies'))
    } catch (err) {
      console.log('err', err)
      dispatch(setError('Something went wrong, please try again'))
    }
  }
}

export function updateCompany(id, data) {
  return async (dispatch) => {
    try {
      const companyUpdateData = await api.updateCompanyData(id, data)
      dispatch(getAllConpanyListSuccess(companyUpdateData))
      dispatch(setSuccessMsg('Company Updated Successfully!', '/companies'))
    } catch (err) {
      console.log('err', err)
      dispatch(setError('Something went wrong, please try again'))
    }
  }
}

export function getCompanySelectList() {
  return async (dispatch) => {
    try {
      const companySelectData = await api.getCompanySelectData()
      dispatch(getCompanySelectListSuccess(companySelectData))
    } catch (err) {
      console.log('err', err)
    }
  }
}

export function getCompanySelectListSuccess(data) {
  return {
    type: types.COMPANY_SELECT_LIST,
    payload: {
      companySelectList: data
    }
  }
}
