import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import RedBtn from '../../components/button/RedBtn'
import WhiteBtn from '../../components/button/WhiteBtn'
import ErrorSpan from '../../components/form/ErrorSpan'
import { setNewPassword } from '../../store/action/userinfo.action'
export default function ChangePasswordForm({ setOpenModal }) {
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors }
  } = useForm()
  const password = useRef({})
  password.current = watch('new_pwd', '')
  const onSubmit = (data) => {
    dispatch(setNewPassword(data))
    setOpenModal(false)
  }
  const checkKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      const data = getValues()
      onSubmit(data)
      setOpenModal(false)
    }
  }
  return (
    <form
      className="modal-form"
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e) => checkKeyDown(e)}
    >
      <div className="input-frame pin">
        <input
          placeholder="Please Enter Old Password"
          {...register('old_pwd', {
            required: 'This field is required!',
            minLength: {
              value: 8,
              message: `Minimum 8 characters including 1 uppercase letter,1 lowercase letter, 1 special character`
            },
            pattern: {
              value:
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
              message: `Minimum 8 characters including 1 uppercase letter,1 lowercase letter, 1 special character`
            }
          })}
        />
      </div>
      {errors.old_pwd && <ErrorSpan role="alert">{errors.old_pwd.message}</ErrorSpan>}
      <div className="input-frame pin">
        <input
          placeholder="Please Enter New Password"
          {...register('new_pwd', {
            required: 'This field is required!',
            minLength: {
              value: 8,
              message: `Minimum 8 characters including 1 uppercase letter,1 lowercase letter, 1 special character`
            },
            pattern: {
              value:
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
              message: `Minimum 8 characters including 1 uppercase letter,1 lowercase letter, 1 special character`
            }
          })}
        />
      </div>
      {errors.new_pwd && <ErrorSpan role="alert">{errors.new_pwd.message}</ErrorSpan>}
      <div className="input-frame pin">
        <input
          placeholder="Comfirm New Password"
          {...register('confirm_pwd', {
            required: 'This field is required!',
            validate: (value) => value === password.current || 'The password do not match'
          })}
        />
      </div>
      {errors.confirm_pwd && <ErrorSpan>{errors.confirm_pwd.message}</ErrorSpan>}
      <div className="btn-frame">
        <WhiteBtn
          text="Close"
          type="cancel"
          width={150}
          height={40}
          marginR={40}
          close={() => setOpenModal(false)}
        />
        <RedBtn text="Submit" type="submit" width={150} height={40} />
      </div>
    </form>
  )
}
