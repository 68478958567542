import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import RedBtn from "../../components/button/RedBtn";
import WhiteBtn from "../../components/button/WhiteBtn";
import ErrorSpan from "../../components/form/ErrorSpan";
import { setNewPin } from "../../store/action/userinfo.action";
export default function UpdatePinForm({ setOpenModal }) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const pin = useRef({});
  pin.current = watch("new_pin", "");
  const onSubmit = (data) => {
    dispatch(setNewPin(data));
    setOpenModal(false);
  };
  const checkKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const data = getValues();
      onSubmit(data);
      setOpenModal(false);

    }
  };
  return (
    <form
      className="modal-form"
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e) => checkKeyDown(e)}
    >
      <div className="input-frame pin">
        <input
          placeholder="Please Enter Old PIN"
          type="number"
          inputMode="numeric"
          maxLength={4}
          minLength={4}
          //input number still can type 'e'
          onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
          onInput={(e) => {
            if (e.target.value.length > e.target.maxLength)
              e.target.value = e.target.value.slice(0, e.target.maxLength)
          }}
          {...register('old_pin', {
            required: 'This field is required!',
            minLength: { value: 4, message: 'Minimum 4 numbers' },
            maxLength: { value: 4, message: 'Maximum 4 numbers' }
          })}
        />
      </div>
      {errors.old_pin && <ErrorSpan role="alert">{errors.old_pin.message}</ErrorSpan>}

      <div className="input-frame pin">
        <input
          placeholder="Please Enter New PIN"
          type="number"
          inputMode="numeric"
          maxLength={4}
          minLength={4}
          //input number still can type 'e'
          onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
          onInput={(e) => {
            if (e.target.value.length > e.target.maxLength)
              e.target.value = e.target.value.slice(0, e.target.maxLength)
          }}
          {...register('new_pin', {
            required: 'This field is required!',
            minLength: { value: 4, message: 'Minimum 4 numbers' },
            maxLength: { value: 4, message: 'Maximum 4 numbers' }
          })}
        />
      </div>
      {errors.new_pin && <ErrorSpan role="alert">{errors.new_pin.message}</ErrorSpan>}
      <div className="input-frame pin">
        <input
          placeholder="Comfirm New PIN"
          type="number"
          inputMode="numeric"
          maxLength={4}
          minLength={4}
          //input number still can type 'e'
          onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
          onInput={(e) => {
            if (e.target.value.length > e.target.maxLength)
              e.target.value = e.target.value.slice(0, e.target.maxLength)
          }}
          {...register('confirm_pin', {
            required: 'This field is required!',
            validate: (value) => value === pin.current || 'The PIN do not match'
          })}
        />
      </div>
      {errors.confirm_pin && <ErrorSpan>{errors.confirm_pin.message}</ErrorSpan>}
      <div className="btn-frame">
        <WhiteBtn
          text="Close"
          type="cancel"
          width={150}
          height={40}
          marginR={40}
          close={() => setOpenModal(false)}
        />
        <RedBtn text="Submit" type="submit" width={150} height={40} />
      </div>
    </form>
  )
}
