import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import ErrorSpan from "./ErrorSpan";
import "react-datepicker/dist/react-datepicker.css";
import { TrainingType, TrainingExpiration } from "../../utils/enums";
import RedBtn from "../button/RedBtn";
import WhiteBtn from "../button/WhiteBtn";
import { ClearIcon } from "../icons/icons";
import {
  createNewTraining,
  updateTraining,
} from "../../store/action/trainings.action";
import FormSelect from "../modal/FormSelect";
export default function TrainingsCreateEditForm({
  close,
  modaltype,
  setOpenModal,
}) {
  const { trainingsDetail } = useSelector((state) => state.trainings);
  const [showTClear, setShowTClear] = useState(false);
  const [showPClear, setShowPClear] = useState(false);
  const dispatch = useDispatch();
  const [inputType, setInputType] = useState("");
  const [inputExpire, setInputExpire] = useState("");
  const {
    register,
    watch,
    control,
    getValues,
    handleSubmit,
    resetField,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: { ...trainingsDetail }, mode: "onTouched" });
  const watchAllFields = watch();

  useEffect(() => {
    reset({
      ...trainingsDetail,
    });
  }, [trainingsDetail, reset]);

  useEffect(() => {
    if (!watchAllFields.title) {
      setShowTClear(false);
    } else {
      setShowTClear(true);
    }
    if (!watchAllFields.price) {
      setShowPClear(false);
    } else {
      setShowPClear(true);
    }
  }, [watchAllFields]);

  const clearFunc = (type) => {
    resetField("title");
    resetField("price");
  };
  const onSubmit = async (data) => {
    if (modaltype === "edit") {
      const newData = {
        training_name: data.training_name,
        type: data.type,
        certi_requred: data.certi_requred ? true : false,
        expiration: Number(data.expiration),
      };
      dispatch(updateTraining(data.id, newData));
      // setOpenModal(false);
    } else {
      const newData = {
        training_name: data.training_name,
        type: data.type,
        certi_requred: data.certi_requred ? true : false,
        expiration: Number(data.expiration),
      };
      const createNew = await dispatch(createNewTraining(newData));
      // setOpenModal(false);
    }
  };
  const typeChangeFunc = (e) => {
    setInputType(e.target.value);
    setValue("type", e.target.value);
  };
  const expireChangeFunc = (e) => {
    setInputExpire(e.target.value);
    setValue("expiration", e.target.value);
  };
  const checkKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const data = getValues();
      onSubmit(data);
    }
  };
  return (
    <form
      className="modal-form"
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e) => checkKeyDown(e)}
    >
      <div className="input-frame">
        <input
          name="training_name"
          placeholder="Training Title"
          {...register("training_name", {
            required: true,
          })}
        />
        {showTClear && (
          <div className="icon-frame">
            <ClearIcon size="20" clear={() => clearFunc("title")} />
          </div>
        )}
      </div>
      {errors.title ? <ErrorSpan>This field is required!</ErrorSpan> : ""}
      <FormSelect
        name="type"
        options={TrainingType}
        control={control}
        change={typeChangeFunc}
      />
      {errors.training_name ? (
        <ErrorSpan>This field is required!</ErrorSpan>
      ) : (
        ""
      )}
      <FormSelect
        name="expiration"
        options={TrainingExpiration}
        control={control}
        change={expireChangeFunc}
      />
      {errors.expiration ? <ErrorSpan>This field is required!</ErrorSpan> : ""}
      <div className="checkbox-frame">
        <input
          {...register("certi_requred")}
          type="checkbox"
          value="certi_requred"
          id="certi_requred"
        />
        <label htmlFor="certi_requred">Certification Number Required</label>
      </div>

      <div className="btn-frame">
        <WhiteBtn
          text="Cancel"
          type="cancel"
          close={close}
          width={200}
          height={50}
          marginR={50}
        />
        <RedBtn text="Submit" type="submit" width={200} height={50} />
      </div>
    </form>
  );
}
