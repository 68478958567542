import React from "react";
import { SearchIcon } from "../icons/icons";

export default function SearchBar({ tableType, searchHandler }) {
  return (
    <div className="search-bar">
      <input name={tableType} onChange={searchHandler} />
      <SearchIcon className="search-icon" size={20} />
    </div>
  );
}
