import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CloseIcon } from "../icons/icons";

export default function CheckinModal({ open, children, className }) {
  const navigate = useNavigate();
  const { classDetail } = useSelector((state) => state.classes);
  const backFunc = () => navigate(-1);
  
  return (
    <>
      {open && (
        <div className={["ckeckin-frame", className].join(" ")}>
          <CloseIcon size={35} close={backFunc} />
          <div className="title">
            <h1>{classDetail.name}</h1>
            <h1>{classDetail.date}</h1>
          </div>
          {children}
        </div>
      )}
    </>
  );
}
