import React from "react";
import { Link } from "react-router-dom";

export default function SubMenuBtn({ title, page, to, onClick }) {
  return (
    <li className="submenu-btn">
      {to ? (
        <Link className={["", page ? "here" : ""].join(" ")} to={to}>
          {title}{" "}
        </Link>
      ) : (
        <button onClick={onClick}>{title}</button>
      )}
      <div className="btn-bg"></div>
    </li>
  );
}
