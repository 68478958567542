export const SET_ERROR = 'SET_ERROR'
export const CLEAR_MSG = 'CLEAR_MSG'
export const SET_SUCCESS_MSG = 'SET_SUCCESS_MSG'
export const SET_MESSAGE = 'SET_MESSAGE'

export const USER_LOGIN_INFO = 'USER_LOGIN_INFO'
export const USER_IS_LOGIN = 'USER_IS_LOGIN'

export const CLASSES_LIST = 'CLASSES_LIST'
export const CLASS_ADD = 'CLASS_ADD'
export const CLASS_DETAIL_INFO = 'CLASS_DETAIL_INFO'
export const CLASS_EDIT = 'CLASS_EDIT'
export const CLASS_ARGS_INUPTCHANGE = 'CLASS_ARGS_INUPTCHANGE'
export const CLASS_BLANK = 'CLASS_BLANK'
export const CLASS_SIGNUPSHEET_DETAIL = 'CLASS_SIGNUPSHEET_DETAIL'

export const ATTENDEE_LIST = 'ATTENDEE_LIST'
export const ATTENDEE_ROW_DATA = 'ATTENDEE_ROW_DATA'
export const ATTENDEE_ADD = 'ATTENDEE_ADD'
export const ATTENDEE_CLASS_LIST = 'ATTENDEE_CLASS_LIST'
export const ATTENDEE_ARGS_INUPTCHANGE = 'ATTENDEE_ARGS_INUPTCHANGE'
export const ATTENDEE_BLANK = 'ATTENDEE_BLANK'
export const ATTENDEE_CSV_DATA = 'ATTENDEE_CSV_DATA'
export const ATTENDEE_CSV_TABLE_HEADER = 'ATTENDEE_CSV_TABLE_HEADER'

export const TRAININGS_LIST = 'TRAININGS_LIST'
export const TRAINING_ADD = 'TRAINING_ADD'
export const TRAINING_ROW_DATA = 'TRAINING_ROW_DATA'
export const TRAINING_EDIT = 'TRAINING_EDIT'
export const TRAINING_ARGS_INUPTCHANGE = 'TRAINING_ARGS_INUPTCHANGE'
export const TRAINING_BLANK = 'TRAINING_BLANK'
export const TRAINING_SELECT_LIST = 'TRAINING_SELECT_LIST'

export const COMPANY_LIST = 'COMPANY_LIST'
export const COMPANY_ADD = 'COMPANY_ADD'
export const COMPANY_ROW_DATA = 'COMPANY_ROW_DATA'
export const COMPANY_EDIT = 'COMPANY_EDIT'
export const COMPANY_ARGS_INUPTCHANGE = 'COMPANY_ARGS_INUPTCHANGE'
export const COMPANY_BLANK = 'COMPANY_BLANK'
export const COMPANY_SELECT_LIST = 'COMPANY_SELECT_LIST'

export const HISTORY_LIST = 'HISTORY_LIST'
export const HISTORY_ROW_DATA = 'HISTORY_ROW_DATA'
export const HISTORY_ROW_ATTENDEES_LIST = 'HISTORY_ROW_ATTENDEES_LIST'
export const HISTORY_SIGNUPSHEET = 'HISTORY_SIGNUPSHEET'
export const HISTORY_ARGS_INUPTCHANGE = 'HISTORY_ARGS_INUPTCHANGE'
export const HISTORY_SIGNUPSHEET_ID = 'HISTORY_SIGNUPSHEET_ID'
export const HISTORY_ATTENDEE_CERT_DATA_BLANK = 'HISTORY_ATTENDEE_CERT_DATA_BLANK'
