import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import ErrorSpan from '../../components/form/ErrorSpan'
import RedBtn from '../../components/button/RedBtn'
import WhiteBtn from '../../components/button/WhiteBtn'
import CheckinModal from '../../components/modal/CheckinModal'
import { checkPin } from '../../store/action/userinfo.action'
import { getClassDetail } from '../../store/action/classes.action'

export default function ClassClosePinCheck() {
  const { classDetail } = useSelector((state) => state.classes)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm()
  const onSubmit = async (data) => {
    const isPinCorrect = await dispatch(checkPin(data.pin))
    if (isPinCorrect) {
      navigate('/classes/signupsheet')
    }
  }
  const backFunc = () => navigate(-1)

  useEffect(() => {
    const class_id = localStorage.getItem('classId')
    if (classDetail.id) {
      dispatch(getClassDetail(classDetail.id))
    } else if (class_id) {
      dispatch(getClassDetail(class_id))
    }
  }, [])

  const checkKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      const data = getValues()
      onSubmit(data)
    }
  }
  return (
    <>
      <CheckinModal open={true} className="three">
        <form
          className="modal-form"
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => checkKeyDown(e)}
        >
          <div className="input-frame pin">
            <input
              type="password"
              autoComplete="on"
              placeholder="Please Enter PIN"
              inputMode="numeric"
              maxLength={4}
              minLength={4}
              //input number still can type 'e'
              onKeyDown={(e) =>
                ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
              }
              onInput={(e) => {
                if (e.target.value.length > e.target.maxLength)
                  e.target.value = e.target.value.slice(0, e.target.maxLength)
              }}
              {...register('pin', {
                required: 'This field is required!',
                minLength: { value: 4, message: 'Minimum 4 numbers' },
                maxLength: { value: 4, message: 'Maximum 4 numbers' },
                pattern: {
                  value: /^(0|[1-9]\d*)(\.\d+)?$/,
                  message: 'Please Enter Number'
                }
              })}
            />
          </div>
          {errors.pin && <ErrorSpan role="alert">{errors.pin.message}</ErrorSpan>}
          <div className="btn-frame">
            <WhiteBtn
              text="Close"
              type="cancel"
              width={200}
              marginR={50}
              close={backFunc}
            />
            <RedBtn text="Submit" type="submit" width={200} />
          </div>
        </form>
      </CheckinModal>
    </>
  )
}
