import React from "react";

export default function MsgModal({ open, children, cName }) {
  return (
    <>
      {open && (
        <div
          className={[
            "msg-bgframe",
            open ? "show" : "",
            cName ? cName : "",
          ].join(" ")}
        >
          <div className={["msg-frame", cName ? cName : ""].join(" ")}>
            {children}
          </div>
        </div>
      )}
    </>
  );
}
