import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { ClearIcon, EyeOpenIcon, EyeCloseIcon } from "./../icons/icons";
import ErrorSpan from "./ErrorSpan";
import RedBtn from "../button/RedBtn";
import { login, getUserInfo } from "../../store/action/userinfo.action";
export default function LoginForm() {
  const dispatch = useDispatch();
  const { isUserLogin } = useSelector((state) => state.userInfo);
  const [showUClear, setShowUClear] = useState(false);
  const [showPClear, setShowPClear] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    watch,
    resetField,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const watchAllFields = watch();

  useEffect(() => {
    if (!watchAllFields.username) {
      setShowUClear(false);
    } else {
      setShowUClear(true);
    }
    if (!watchAllFields.password) {
      setShowPClear(false);
    } else {
      setShowPClear(true);
    }
    // const checkStatus = () => {
    //   if (isUserLogin) {
    //     navigate("/classes");
    //   }
    // };
    // checkStatus();
  }, [watchAllFields, isUserLogin, navigate]);

  //clear input value
  const clearFunc = (type) => {
    if (type === "username") {
      resetField("username");
    } else {
      resetField("password");
    }
  };

  //login submit
  const onSubmit = async (data) => {
    const isLogin = await dispatch(login(data));
    if (isLogin === true) {
      await dispatch(getUserInfo());
      navigate("/classes");
    }
  };
  const checkKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const data = getValues();
      onSubmit(data);
    }
  };
  return (
    <form
      className="login-form"
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e) => checkKeyDown(e)}
    >
      <div className="input-frame">
        <input
          placeholder="Username"
          autoComplete="username"
          {...register("username", { required: true })}
        />
        {showUClear && (
          <div className="icon-frame">
            <ClearIcon size="20" clear={() => clearFunc("username")} />
          </div>
        )}
      </div>
      {errors.username ? <ErrorSpan>This field is required!</ErrorSpan> : ""}
      <div className="input-frame">
        <input
          placeholder="Password"
          type={isRevealPwd ? "text" : "password"}
          autoComplete="current-password"
          {...register("password", {
            required: true,
          })}
        />
        {showPClear && (
          <div className="icon-frame">
            {isRevealPwd ? (
              <EyeOpenIcon
                size="20"
                className="eye-icon"
                openCloseEye={() => setIsRevealPwd((prevState) => !prevState)}
              />
            ) : (
              <EyeCloseIcon
                size="20"
                className="eye-icon"
                openCloseEye={() => setIsRevealPwd((prevState) => !prevState)}
              />
            )}
            <ClearIcon size="20" clear={() => clearFunc("password")} />
          </div>
        )}
      </div>
      {errors.password ? <ErrorSpan>This field is required!</ErrorSpan> : ""}
      <RedBtn text="Log In" type="submit" width={150} />
    </form>
  );
}
