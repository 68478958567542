import * as types from '../actionTypes'
import * as api from '../../api/userApi'
import { getLoginAuthError, setSuccessMsg } from './message.action'

export function login(data) {
  return async (dispatch) => {
    try {
      const isLogin = await api.login(data)
      dispatch(isLoginSuccess(isLogin))
      return isLogin
    } catch (err) {
      if (err) {
        console.log('err', err)
        dispatch(getLoginAuthError(err.response.data.msg))
      }
    }
  }
}

export function getUserInfo() {
  return async (dispatch) => {
    try {
      let loginUser = await api.getUser()
      if (loginUser.loggedIn === false) {
        dispatch(getLoginAuthError('Please Login Again', '/'))
      } else {
        dispatch(getUserInfoSuccess(loginUser))
      }
    } catch (err) {
      if (err) {
        console.log('err', err)
        if (err.response.data.message === 'Unauthorized!') {
          dispatch(getLoginAuthError('Please Login Again', '/'))
        }
      }
    }
  }
}

export function checkPin(data) {
  return async (dispatch) => {
    try {
      let isPinCorrect = await api.checkPin(data)
      if (!isPinCorrect) {
        dispatch(setError('Please Enter Correct Pin'))
      }
      return isPinCorrect
    } catch (err) {
      if (err) {
        console.log('err', err)
      }
    }
  }
}

export const getLoginInfoError = (msg) => {
  return {
    type: types.SET_ERROR,
    payload: {
      msg,
      isLogout: true
    }
  }
}

export function getUserInfoSuccess(loginUser) {
  return {
    type: types.USER_LOGIN_INFO,
    payload: {
      loginUser
    }
  }
}

export function setError(error, redireUrl) {
  let payload = { msg: error }
  if (redireUrl) {
    payload = { ...payload, redireUrl }
  }
  return {
    type: types.SET_ERROR,
    payload
  }
}

export function isLoginSuccess(isLogin) {
  return {
    type: types.USER_IS_LOGIN,
    payload: {
      isUserLogin: isLogin
    }
  }
}

export function getUserLogout(currentUser) {
  return async (dispatch) => {
    try {
      const isLogout = await api.logout(currentUser)
      dispatch(getUserInfoSuccess({}))
      dispatch(isLoginSuccess(isLogout))
    } catch (err) {
      if (err) {
        console.log('err', err)
      } else {
        console.log('err', err)
      }
    }
  }
}

export function setNewPin(data) {
  return async (dispatch) => {
    try {
      const setNewPin = await api.setNewPin(data)
      dispatch(setSuccessMsg('Set New PIN Successfully', '/portfolio'))
      return setNewPin
    } catch (err) {
      if (err) {
        console.log('err', err)
        dispatch(setError('Something went wrong, please try again'))
      }
    }
  }
}

export function setNewPassword(data) {
  return async (dispatch) => {
    try {
      const setNewPassword = await api.setNewPassword(data)
      dispatch(setSuccessMsg('Set New Password Successfully', '/portfolio'))
      return setNewPassword
    } catch (err) {
      if (err) {
        console.log('err', err)
        dispatch(setError('Something went wrong, please try again'))
      }
    }
  }
}

export function setNewUsername(data) {
  return async (dispatch) => {
    try {
      const setNewUsername = await api.setNewUsername(data)
      const loginUser = await api.getUser()
      dispatch(getUserInfoSuccess(loginUser))
      dispatch(setSuccessMsg('Set New Username Successfully', '/portfolio'))
      return setNewUsername
    } catch (err) {
      if (err) {
        console.log('err', err)
        dispatch(setError('Something went wrong, please try again'))
      }
    }
  }
}
export function setNewEmail(data) {
  return async (dispatch) => {
    try {
      const setNewEmail = await api.setNewEmail(data)
      dispatch(setSuccessMsg('Set New Email Successfully', '/portfolio'))
      return setNewEmail
    } catch (err) {
      if (err) {
        console.log('err', err)
        dispatch(setError('Something went wrong, please try again'))
      }
    }
  }
}

export function forgotUsername(data) {
  return async (dispatch) => {
    try {
      const forgotUsernameEmail = await api.forgotUsername(data)
      dispatch(setSuccessMsg('Username reset email has been sent'))
    } catch (err) {
      if (err) {
        console.log('err', err)
        dispatch(setError('Something went wrong, please try again'))
      }
    }
  }
}

export function forgotPassword(data) {
  return async (dispatch) => {
    try {
      const forgotPasswordEmail = await api.forgotPassword(data)
      dispatch(setSuccessMsg('Password reset email has been sent'))
    } catch (err) {
      if (err) {
        console.log('err', err)
        dispatch(setError('Something went wrong, please try again'))
      }
    }
  }
}
