import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../../components/layout/Layout'
import ReactTable from '../../components/table/ReactTable'
import { EditIcon, DeleteIcon, CloseIcon } from '../../components/icons/icons'
import Modal from '../../components/modal/Modal'
import MsgModal from '../../components/modal/MsgModal'
import CompanyCreateEditForm from '../../components/form/CompanyCreateEditForm'
import RedBtn from '../../components/button/RedBtn'
import WhiteBtn from '../../components/button/WhiteBtn'
import {
  getAllCompany,
  getRowCompany,
  blankCompanyData,
  deleteRowCompany
} from '../../store/action/companies.action'

export default function CompaniesDashboard() {
  const { allCompanyList } = useSelector((state) => state.companies)
  const [openModal, setOpenModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [modaltype, setmodaltype] = useState('')
  const [deleteId, setDeleteId] = useState('')
  const [isLoading, setLoading] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    setLoading(true)
    dispatch(getAllCompany())
    if (allCompanyList) {
      setTimeout(() => {
        setLoading(false)
      }, [500])
    }
  }, [dispatch])

  const editRowFunc = useCallback(
    (data) => {
      setmodaltype('edit')
      dispatch(getRowCompany(data))
      setOpenModal(true)
    },
    [dispatch]
  )

  const addCompanyHandler = () => {
    setmodaltype('create')
    dispatch(blankCompanyData())
    setOpenModal(true)
  }

  const deleteClassHandler = (id) => {
    setDeleteId(id)
    setOpenDeleteModal(true)
  }

  const deleteRowFunc = useCallback(() => {
    dispatch(deleteRowCompany(deleteId))
    setOpenDeleteModal(false)
  }, [dispatch, deleteId])

  const searchHandler = (e) => {
    dispatch(getAllCompany(e.target.value))
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Company Name',
        accessor: 'company_name',
        width: '25%',
        isSort: true,
        Cell: ({ row }) => {
          return <span>{row.original.company_name}</span>
        }
      },
      {
        Header: 'Contact Name',
        accessor: 'admin_name',
        width: '20%',
        isSort: true,
        Cell: ({ row }) => {
          return <span>{row.original.admin_name}</span>
        }
      },
      {
        Header: 'Contact Email',
        accessor: 'admin_email',
        width: '20%',
        isSort: true,
        Cell: ({ row }) => {
          return <span>{row.original.admin_email}</span>
        }
      },
      {
        Header: 'Edit',
        width: '5%',
        isSort: false,
        Cell: ({ row }) => {
          return (
            <span>
              <EditIcon size={20} onClick={() => editRowFunc(row.original.id)} />
            </span>
          )
        }
      },
      {
        Header: 'Delete',
        width: '5%',
        isSort: false,
        Cell: ({ row }) => {
          return (
            <span>
              <DeleteIcon size={20} onClick={() => deleteClassHandler(row.original.id)} />
            </span>
          )
        }
      }
    ],
    [editRowFunc]
  )
  return (
    <>
      <Layout
        page="company"
        mainPage="dashboard"
        title="Dashboard"
        isCreateBtn={true}
        modaltype={modaltype}
        open={addCompanyHandler}
      >
        <ReactTable
          columns={columns}
          data={allCompanyList}
          tableSize={5}
          isShowPagination={true}
          title="Companies"
          isSorted={true}
          tableType="companies"
          searchHandler={searchHandler}
          searchBarShow={true}
          isLoading={isLoading}
        />
      </Layout>
      {openModal && (
        <Modal
          open={openModal}
          close={() => setOpenModal(false)}
          title={modaltype === 'edit' ? 'Edit Company Detail' : 'Create New Company'}
          wSize={80}
          hSize={80}
        >
          <CompanyCreateEditForm
            close={() => setOpenModal(false)}
            modaltype={modaltype}
            setOpenModal={setOpenModal}
          />
        </Modal>
      )}
      {openDeleteModal && (
        <MsgModal open={openDeleteModal}>
          <div className="delete-modal-frame">
            <CloseIcon size="30" onClick={() => setOpenDeleteModal(false)} />
            <h1>Are You Sure To Delete?</h1>
            <div className="btn-frame">
              <WhiteBtn
                text="Close"
                type="cancel"
                width={150}
                height={40}
                marginR={40}
                close={() => setOpenDeleteModal(false)}
              />
              <RedBtn
                text="Submit"
                type="submit"
                width={150}
                height={40}
                onClickFunc={deleteRowFunc}
              />
            </div>
          </div>
        </MsgModal>
      )}
    </>
  )
}
