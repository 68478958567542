import React from 'react'
import SignatureCanvas from 'react-signature-canvas'
import RedBtn from '../button/RedBtn'
import WhiteBtn from '../button/WhiteBtn'

export default function Signature({
  setSigShow,
  canvas,
  handleGetCanvas,
  clearSignatureCanvas,
  padRef,
}) {
  return (
    <>
      <SignatureCanvas
        penColor="black"
        canvasProps={{ className: 'sigCanvas' }}
        ref={padRef}
      />
      <div className="btn-frame">
        <WhiteBtn
          text={canvas ? 'Cancel' : 'Clear'}
          type="cancel"
          width={200}
          marginR={50}
          close={canvas ? () => setSigShow(false) : clearSignatureCanvas}
        />
        <RedBtn text="Save" type="submit" width={200} onClickFunc={handleGetCanvas} />
      </div>
    </>
  )
}
