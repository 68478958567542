import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import classesReducer from "./reducers/classes.reducer";
import attendeeReducer from "./reducers/attendee.reducer";
import trainingsReducer from "./reducers/trainings.reducer";
import companiesReducer from "./reducers/companies.reducer";
import historyReducer from "./reducers/history.reducer";
import userInfoReducer from "./reducers/userinfo.reducer";
import messageReducer from "./reducers/message.reducer.js";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const storeConfig = () => {
  const store = createStore(
    combineReducers({
      classes: classesReducer,
      attendee: attendeeReducer,
      trainings: trainingsReducer,
      companies: companiesReducer,
      history: historyReducer,
      userInfo: userInfoReducer,
      message: messageReducer,
    }),
    composeEnhancers(applyMiddleware(thunk))
  );

  return store;
};

export default storeConfig;
