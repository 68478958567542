import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
// import Moment from 'react-moment'
import Layout from '../../components/layout/Layout'
import ReactTable from '../../components/table/ReactTable'
import Modal from '../../components/modal/Modal'
import CreateClassForm from '../../components/form/CreateClassForm'
import {
  getAllClasses,
  getClassDetail,
  blankClassData,
  deleteRowClass
} from '../../store/action/classes.action'
import { EditIcon, DeleteIcon, CloseIcon } from '../../components/icons/icons'
import MsgModal from '../../components/modal/MsgModal'
import RedBtn from '../../components/button/RedBtn'
import WhiteBtn from '../../components/button/WhiteBtn'

export default function ClassesDashboard() {
  const { allClassesList } = useSelector((state) => state.classes)
  const [openModal, setOpenModal] = useState(false)
  const [modaltype, setmodaltype] = useState('')
  const [deleteId, setDeleteId] = useState('')
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)
    dispatch(getAllClasses())
    if (allClassesList) {
      setTimeout(() => {
        setLoading(false)
      }, [500])
    }
  }, [dispatch])

  const fetchClassDetail = useCallback(
    (data) => {
      localStorage.setItem('classId', data)
      dispatch(getClassDetail(data))
      navigate('/classes/detail')
    },
    [dispatch, navigate]
  )

  const editRowFunc = useCallback(
    (data) => {
      setmodaltype('edit')
      dispatch(getClassDetail(data))
      setOpenModal(true)
    },
    [dispatch]
  )

  const addClassHandler = () => {
    setmodaltype('create')
    dispatch(blankClassData())
    setOpenModal(true)
  }

  const deleteClassHandler = (id) => {
    setDeleteId(id)
    setOpenDeleteModal(true)
  }

  const deleteRowFunc = useCallback(() => {
    dispatch(deleteRowClass(deleteId))
    setOpenDeleteModal(false)
  }, [dispatch, deleteId])

  const searchHandler = (e) => {
    dispatch(getAllClasses(e.target.value))
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date',
        width: '10%',
        isSort: true,
        Cell: ({ row }) => {
          return (
            <span onClick={() => fetchClassDetail(row.original.id)}>
              {/* <Moment format="MM/DD/yyyy">{row.original.date}</Moment> */}
              {row.original.date}
            </span>
          )
        }
      },
      {
        Header: 'Class Name',
        accessor: 'name',
        width: '25%',
        isSort: true,
        Cell: ({ row }) => {
          return (
            <span onClick={() => fetchClassDetail(row.original.id)}>
              {row.original.name}
            </span>
          )
        }
      },
      {
        Header: 'Company',
        accessor: 'company',
        width: '20%',
        isSort: true,
        Cell: ({ row }) => {
          return (
            <span onClick={() => fetchClassDetail(row.original.id)}>
              {row.original.company}
            </span>
          )
        }
      },
      {
        Header: 'Check In',
        accessor: 'ckeck_num',
        width: '7%',
        isSort: true,
        Cell: ({ row }) => {
          return (
            <span onClick={() => fetchClassDetail(row.original.id)}>
              {row.original.ckeck_num}
            </span>
          )
        }
      },
      {
        Header: 'Edit',
        width: '5%',
        isSort: false,
        Cell: ({ row }) => {
          return (
            <span>
              <EditIcon size={20} onClick={() => editRowFunc(row.original.id)} />
            </span>
          )
        }
      },
      {
        Header: 'Delete',
        width: '5%',
        isSort: false,
        Cell: ({ row }) => {
          return (
            <span>
              <DeleteIcon size={20} onClick={() => deleteClassHandler(row.original.id)} />
            </span>
          )
        }
      }
    ],
    [fetchClassDetail, editRowFunc]
  )
  return (
    <>
      <Layout
        mainPage="dashboard"
        page="classes"
        title="Dashboard"
        isCreateBtn={true}
        open={addClassHandler}
      >
        <ReactTable
          columns={columns}
          data={allClassesList}
          tableSize={5}
          isShowPagination={true}
          title="Classes"
          isSorted={true}
          tableType="classes"
          isRowClickable={true}
          searchHandler={searchHandler}
          searchBarShow={true}
          isLoading={isLoading}
        />
      </Layout>
      {openModal && (
        <Modal
          open={openModal}
          close={() => setOpenModal(false)}
          title={modaltype === 'edit' ? 'Edit Classes Detail' : 'Setup A Class'}
          wSize={80}
          hSize={80}
        >
          <CreateClassForm
            close={() => setOpenModal(false)}
            setOpenModal={setOpenModal}
            modaltype={modaltype}
          />
        </Modal>
      )}

      {openDeleteModal && (
        <MsgModal open={openDeleteModal}>
          <div className="delete-modal-frame">
            <CloseIcon size="30" onClick={() => setOpenDeleteModal(false)} />
            <h1>Are You Sure To Delete?</h1>
            <div className="btn-frame">
              <WhiteBtn
                text="Close"
                type="cancel"
                width={150}
                height={40}
                marginR={40}
                close={() => setOpenDeleteModal(false)}
              />
              <RedBtn
                text="Submit"
                type="submit"
                width={150}
                height={40}
                onClickFunc={deleteRowFunc}
              />
            </div>
          </div>
        </MsgModal>
      )}
    </>
  )
}
