import React, { useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ReactToPrint from 'react-to-print'
import html2pdf from 'js-html2pdf'
import { PrinterIcon, EmailSentIcon } from '../../components/icons/icons'
import WhiteBtn from '../../components/button/WhiteBtn'
import SignUpSheet from '../../components/table/SignUpSheet'
import { getSignUpSheet } from '../../store/action/history.action'

export default function ClassCertification({ setOpenModal }) {
  const { signUpSheetDetail, signUpSheetID } = useSelector((state) => state.history)
  const dispatch = useDispatch()
  const componentRef = useRef(null)

  useEffect(() => {
    dispatch(getSignUpSheet(signUpSheetID))
  }, [dispatch, signUpSheetID])

  const handleDownload = () => {
    var element = document.getElementById('signUpSheetPDF')
    var options = {
      filename: `${signUpSheetDetail.className}_${signUpSheetDetail.date}.pdf`,
      jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' },
      image: { type: 'jpg' },
      html2canvas: { useCORS: true, scale: 2 }
    }
    options.source = element
    options.download = true
    html2pdf.getPdf(options)
  }

  return (
    <div className="certificate-frame">
      <div className="top">
        <ReactToPrint
          trigger={() => <PrinterIcon size="30" className="printer-icon" />}
          content={() => componentRef.current}
        />
        <a
          href="mailto:"
          target="_blank"
          rel="noreferrer"
          onClick={() => handleDownload()}
        >
          <EmailSentIcon size="30" />
        </a>
      </div>
      <div className="template-frame">
        <SignUpSheet printRef={componentRef} />
      </div>
      <WhiteBtn
        text="Close"
        type="cancel"
        width={150}
        height={40}
        close={() => setOpenModal(false)}
      />
    </div>
  )
}
