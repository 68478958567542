import * as types from "../actionTypes";

const initState = {
  allHistoryList: [],
  searchArgs: {},
  historyDetail: {},
  signUpSheetDetail: {},
  signUpSheetID: "",
  classHistorydata: {},
  classAttendeesHistorydata: [],
  attendeeCertAddEditDetail: {}
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.HISTORY_LIST: {
      return { ...state, allHistoryList: [...action.payload.allHistoryList] };
    }
    case types.HISTORY_SIGNUPSHEET: {
      return {
        ...state,
        signUpSheetDetail: { ...action.payload.signUpSheetDetail },
      };
    }
    case types.HISTORY_ARGS_INUPTCHANGE: {
      return {
        ...state,
        ...action.payload,
        searchArgs: { ...action.payload.searchArgs },
      };
    }
    case types.HISTORY_SIGNUPSHEET_ID: {
      return {
        ...state,
        ...action.payload,
        signUpSheetID: action.payload.signUpSheetID,
      };
    }
    case types.HISTORY_ROW_DATA: {
      return {
        ...state,
        ...action.payload,
        classHistorydata: action.payload.classHistorydata,
      };
    }
    case types.HISTORY_ROW_ATTENDEES_LIST: {
      return {
        ...state,
        ...action.payload,
        classAttendeesHistorydata: action.payload.classAttendeesHistorydata
      }
    }
    case types.HISTORY_ATTENDEE_CERT_DATA_BLANK: {
      return {
        ...state,
        ...action.payload,
        attendeeCertAddEditDetail: { ...action.payload.attendeeCertAddEditDetail }
      }
    }
    default:
      return state
  }
}

export default reducer
