import * as types from '../actionTypes'
import * as api from '../../api/historyApi'
import { setError, setSuccessMsg } from './message.action'

export function getAllHistory(searchArgs) {
  return async (dispatch, getState) => {
    try {
      let allHistoryData = await api.getHistoryList()
      if (searchArgs) {
        allHistoryData = Object.keys(searchArgs).reduce(
          (pre, key) => {
            pre = pre.filter((item) => {
              if (item.date.toString().includes(searchArgs)) {
                return true
              }
              if (item.attendeesNum.toString().includes(searchArgs)) {
                return true
              }
              if (
                item.className
                  .toLocaleLowerCase()
                  .includes(searchArgs.toLocaleLowerCase())
              ) {
                return true
              }
              return Object.values(item).includes(searchArgs)
            })
            return pre
          },
          [...allHistoryData]
        )
      }
      dispatch(getAllHistoryListSuccess(allHistoryData))
    } catch (err) {
      console.log('err', err)
      if (err.response.data.message === 'Unauthorized!') {
        dispatch(setError('Please Login Again', '/'))
      }
    }
  }
}

export function getClassHistory(id, searchArgs) {
  return async (dispatch, getState) => {
    try {
      let allHistoryData = await api.getHistoryList()
      let classHistory = allHistoryData.filter((i) => i.id === id)
      if (searchArgs) {
        classHistory[0].attendees = Object.keys(searchArgs).reduce(
          (pre, key) => {
            pre = pre.filter((item) => {
              if (item.firstName.toLocaleLowerCase().includes(searchArgs)) {
                return true
              }
              if (item.lastName.toLocaleLowerCase().includes(searchArgs)) {
                return true
              }
              if (item.company.toLocaleLowerCase().includes(searchArgs)) {
                return true
              }
              return Object.values(item).includes(searchArgs)
            })
            return pre
          },
          [...classHistory[0].attendees]
        )
      }
      dispatch(getHistoryRowDataSuccess(classHistory[0]))
      dispatch(getHistoryRowAttendeesListSuccess(classHistory[0].attendees))
    } catch (err) {
      console.log('err', err)
      if (err.response.data.message === 'Unauthorized!') {
        dispatch(setError('Please Login Again', '/'))
      }
    }
  }
}

export function getSignUpSheet(id) {
  return async (dispatch, getState) => {
    try {
      let signupSheetData = await api.signupSheetData(id)
      dispatch(getSignupSheetSuccess(signupSheetData))
    } catch (err) {
      console.log('err', err)
      if (err.response.data.message === 'Unauthorized!') {
        dispatch(setError('Please Login Again', '/'))
      }
    }
  }
}

export function searchArgsChange(searchArgs) {
  return {
    type: types.HISTORY_ARGS_INUPTCHANGE,
    payload: {
      searchArgs
    }
  }
}

export function getAllHistoryListSuccess(data) {
  return {
    type: types.HISTORY_LIST,
    payload: {
      allHistoryList: data
    }
  }
}

export function getHistoryRowDataSuccess(data) {
  return {
    type: types.HISTORY_ROW_DATA,
    payload: {
      classHistorydata: data
    }
  }
}

export function getHistoryRowAttendeesListSuccess(data) {
  return {
    type: types.HISTORY_ROW_ATTENDEES_LIST,
    payload: {
      classAttendeesHistorydata: data
    }
  }
}

export function getSignupSheetSuccess(data) {
  return {
    type: types.HISTORY_SIGNUPSHEET,
    payload: {
      signUpSheetDetail: data
    }
  }
}

export function saveSignUpSheetID(id) {
  return {
    type: types.HISTORY_SIGNUPSHEET_ID,
    payload: {
      signUpSheetID: id
    }
  }
}

export function attendeeCertAddEditData(data) {
  const attendeeCertAddEditData = {
    canEdit: data ? data.canEdit : '',
    cert_number: data ? data.cert_number : '',
    company: data ? data.company : '',
    firstName: data ? data.firstName : '',
    id: data ? data.id : '',
    lastName: data ? data.lastName : '',
    pass: data ? data.pass : '',
    ssn: data ? data.ssn : ''
  }
  return {
    type: types.HISTORY_ATTENDEE_CERT_DATA_BLANK,
    payload: {
      attendeeCertAddEditDetail: attendeeCertAddEditData
    }
  }
}

export function switchBtn(class_id, data, originalBtn) {
  return async (dispatch, getState) => {
    try {
      let classHistory = await api.signupSheetData(class_id)
      classHistory.attendees.forEach((object) => {
        if (object.id === data.id) {
          object['canEdit'] = !originalBtn
        }
      })
      dispatch(getHistoryRowAttendeesListSuccess(classHistory.attendees))
    } catch (err) {
      console.log('err', err)
      if (err.response.data.message === 'Unauthorized!') {
        dispatch(setError('Please Login Again', '/'))
      }
    }
  }
}

export function addNewCertifiNum(class_id, data) {
  return async (dispatch, getState) => {
    try {
      // const state = getState()
      // const { attendeeCertAddEditDetail } = state.history
      // if (!attendeeCertAddEditDetail.cert_number) {
      if (!data || !data.cert_number) {
        dispatch(setError('Please Enter Certificate Number!'))
      } else if (data.cert_number.length < 3 || data.cert_number.length > 30) {
        dispatch(setError('Please Enter 3-30 Certificate Numbers!'))
      } else {
        let addNewCertifiNum = await api.addNewCertifiNumber(class_id, data)
        if (addNewCertifiNum) {
          dispatch(getHistoryRowAttendeesListSuccess(addNewCertifiNum.data.attendees))
          await dispatch(setSuccessMsg('Add New Certificate Number Successfully'))
          // dispatch(attendeeCertAddEditData())
          localStorage.removeItem('attendeeData')
        } else {
          dispatch(setError('Something went wrong, please try later', '/history'))
        }
      }
    } catch (err) {
      console.log('err', err)
      if (err.response.data.message === 'Unauthorized!') {
        dispatch(setError('Please Login Again', '/'))
      }
    }
  }
}
export function updatCertifiNum(class_id, data) {
  return async (dispatch, getState) => {
    // const state = getState()
    // const { attendeeCertAddEditDetail } = state.history
    try {
      // if (!attendeeCertAddEditDetail.cert_number) {
      if (!data || !data.cert_number) {
        dispatch(setError('Please Enter 3-30 Certificate Numbers!'))
      } else if (data.cert_number.length < 3 || data.cert_number.length > 30) {
        dispatch(setError('Please Enter 3-30 Certificate Numbers!'))
      } else {
        let updatCertifiNum = await api.updateCertifiNumber(
          class_id,
          // attendeeCertAddEditDetail
          data
        )
        if (updatCertifiNum) {
          dispatch(getHistoryRowAttendeesListSuccess(updatCertifiNum.data.attendees))
          await dispatch(setSuccessMsg('Edit Certificate Number Successfully'))
          localStorage.removeItem('attendeeData')
          // dispatch(attendeeCertAddEditData())
        } else {
          dispatch(setError('Something went wrong, please try later', '/history'))
        }
      }
    } catch (err) {
      console.log('err', err)
      if (err.response.data.message === 'Unauthorized!') {
        dispatch(setError('Please Login Again', '/'))
      }
    }
  }
}
