import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { CloseIcon, ErrorIcon, ClearIcon } from './../icons/icons'
import MsgModal from '../modal/MsgModal'
import WhiteBtn from '../button/WhiteBtn'
import RedBtn from '../button/RedBtn'
import ErrorSpan from './ErrorSpan'
import { forgotPassword } from '../../store/action/userinfo.action'

export default function ResetPasswordForm({ open, setIsPassWModalOpen }) {
  const [showPClear, setShowPClear] = useState(false)
  const {
    register,
    watch,
    handleSubmit,
    resetField,
    getValues,
    formState: { errors }
  } = useForm()
  const watchAllFields = watch()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!watchAllFields.email) {
      setShowPClear(false)
    } else {
      setShowPClear(true)
    }
  }, [watchAllFields])

  //clear input value
  const clearFunc = (type) => {
    resetField('email')
  }
  const onSubmit = (data) => {
    dispatch(forgotPassword(data.email))
    setIsPassWModalOpen(false)
  }
  const checkKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      const data = getValues()
      onSubmit(data)
    }
  }
  return (
    <MsgModal open={open}>
      <div className="reset-form">
        <div className="top">
          <h1>Forgot your password?</h1>
          <CloseIcon size="30" onClick={() => setIsPassWModalOpen(false)} />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => checkKeyDown(e)}>
          <div className="input-frame">
            <input
              placeholder="Email"
              {...register('email', {
                required: 'This field is required!',
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Invalid email address'
                }
              })}
            />
            {showPClear && (
              <div className="icon-frame">
                <ClearIcon size="20" clear={() => clearFunc('email')} />
              </div>
            )}
          </div>
          {errors.email && <ErrorSpan role="alert">{errors.email.message}</ErrorSpan>}
          <div className="btn-frame">
            <WhiteBtn
              text="Close"
              type="cancel"
              width={150}
              marginR={40}
              close={() => setIsPassWModalOpen(false)}
            />
            <RedBtn text="Submit" type="submit" width={150} />
          </div>
        </form>
      </div>
    </MsgModal>
  )
}
