import React, { useState, useEffect, useRef } from 'react'
import { useForm, Controller } from 'react-hook-form'
import ErrorSpan from './ErrorSpan'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { ClassType } from '../../utils/enums'
import RedBtn from '../button/RedBtn'
import WhiteBtn from '../button/WhiteBtn'
import { createNewClass, updateClass } from '../../store/action/classes.action'
import { getTrainingSelectList } from '../../store/action/trainings.action'
import { getCompanySelectList } from '../../store/action/companies.action'
import FormSelect from '../modal/FormSelect'
export default function CreateClassForm({ close, setOpenModal, modaltype }) {
  const { classDetail } = useSelector((state) => state.classes)
  const { trainingsSelectList } = useSelector((state) => state.trainings)
  const { companySelectList } = useSelector((state) => state.companies)
  const [inputType, setInputType] = useState('')
  const pickerRef = useRef(null)
  // JUSTIN CODE, EXPERIMENT FOR API LISTS
  // const [lists, setLists] = useState({
  //   companies: [],
  //   trainings: [],
  //   trainingsFiltered: [],
  //   classType: [],
  // });
  // const [isLoading, setLoading] = useState(true);
  // JUSTIN CODE, EXPERIMENT FOR API LISTS

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors }
  } = useForm({ defaultValues: { ...classDetail }, mode: 'onTouched' })
  const dispatch = useDispatch()

  // JUSTIN'S CODE, FETCHING LISTS
  // useEffect(() => {
  //   dispatch(getCompanySelectList());
  //   dispatch(getTrainingSelectList());
  //   const fetch = async () => {
  //     // const companies = await Companies();
  //     // const trainings = await Trainings();
  //     const classType = ClassType;

  //     setLoading(false);
  //     setLists({
  //       companies: companySelectList,
  //       trainings: trainingsSelectList,
  //       trainingsFiltered: [],
  //       classType,
  //     });
  //   };

  //   if (isLoading) {
  //     fetch();
  //   }
  // }, [isLoading]);
  // JUSTIN'S CODE, FETCHING LISTS

  useEffect(() => {
    dispatch(getCompanySelectList())
    dispatch(getTrainingSelectList())
    reset({
      ...classDetail
    })
    if (classDetail.type) {
      setInputType(classDetail.type)
    }
    //ReactDatePicker input readOnly can off keyboard pop up
    if (pickerRef.current !== null) {
      pickerRef.current.input.readOnly = true
    }
  }, [classDetail, reset, pickerRef])

  const onSubmit = (data) => {
    if (modaltype === 'edit') {
      const updateData = {
        date: data.date,
        training_id: data.training_id,
        type: data.type,
        company_id: data.company_id,
        location: data.location
      }
      dispatch(updateClass(data.id, updateData))
      setOpenModal(false)
    } else {
      const newData = {
        date: data.date,
        training_id: data.training_id,
        type: data.type,
        company_id: data.company_id
      }
      dispatch(createNewClass(newData))
      setOpenModal(false)
    }
  }

  const typeChangeFunc = (e) => {
    setInputType(e.target.value)
    setValue('type', e.target.value)
    dispatch(getTrainingSelectList(e.target.value))
  }
  const nameChangeFunc = (e) => {
    setValue('training_id', e.target.value)
  }
  const compantChangeFunc = (e) => {
    setValue('company_id', e.target.value)
  }
  const checkKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      const data = getValues()
      onSubmit(data)
    }
  }

  return (
    <form
      className="modal-form"
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e) => checkKeyDown(e)}
    >
      <FormSelect
        name="type"
        options={ClassType}
        control={control}
        change={typeChangeFunc}
      />
      {errors.type ? <ErrorSpan>This field is required!</ErrorSpan> : ''}

      {(inputType === 'safety' ||
        inputType === 'equipment' ||
        inputType === 'services') && (
        <FormSelect
          name="training_id"
          options={trainingsSelectList}
          control={control}
          change={nameChangeFunc}
        />
      )}

      {errors.training_id ? <ErrorSpan>This field is required!</ErrorSpan> : ''}

      <Controller
        control={control}
        name="date"
        rules={{ required: true }}
        render={({ field }) => (
          <ReactDatePicker
            placeholderText="Select date"
            dateFormat="MM-dd-yyyy"
            onChange={(date) => field.onChange(moment(date).toDate())}
            selected={field.value ? moment(field.value).toDate() : moment().toDate()}
            minDate={moment().toDate()}
            popperPlacement="bottom"
            ref={pickerRef}
          />
        )}
      />
      {errors.date ? <ErrorSpan>This field is required!</ErrorSpan> : ''}
      <FormSelect
        name="company_id"
        options={companySelectList}
        control={control}
        change={compantChangeFunc}
      />
      {errors.company_id ? <ErrorSpan>This field is required!</ErrorSpan> : ''}
      <div className="btn-frame">
        <WhiteBtn text="Cancel" type="cancel" close={close} width={200} marginR={50} />
        <RedBtn text="submit" type="submit" width={200} />
      </div>
    </form>
  )
}
