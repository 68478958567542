import * as types from "../actionTypes";

const initState = {
  allAttendeeList: [],
  searchArgs: {},
  attendeeDetail: {},
  attendeeClassList: [],
  attendeeCSVList: [],
  attendeeCSVTableHeader: [],
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case types.ATTENDEE_LIST: {
      return { ...state, allAttendeeList: [...action.payload.allAttendeeList] }
    }
    case types.ATTENDEE_ROW_DATA: {
      return { ...state, attendeeDetail: { ...action.payload.attendeeDetail } }
    }
    case types.ATTENDEE_CLASS_LIST: {
      return {
        ...state,
        attendeeClassList: [...action.payload.attendeeClassList]
      }
    }
    case types.ATTENDEE_ARGS_INUPTCHANGE: {
      return {
        ...state,
        ...action.payload,
        searchArgs: { ...action.payload.searchArgs }
      }
    }
    case types.ATTENDEE_BLANK: {
      return {
        ...state,
        ...action.payload,
        attendeeDetail: { ...action.payload.attendeeDetail }
      }
    }
    case types.ATTENDEE_CSV_DATA: {
      return {
        ...state,
        ...action.payload,
        attendeeCSVList: [...action.payload.attendeeCSVList]
      }
    }
    case types.ATTENDEE_CSV_TABLE_HEADER: {
      return {
        ...state,
        ...action.payload,
        attendeeCSVTableHeader: [...action.payload.attendeeCSVTableHeader]
      }
    }
    default:
      return state
  }
};

export default reducer;
