import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import ReactTable from './ReactTable'

export default function ReviewCSVFile() {
  const { attendeeCSVList, attendeeCSVTableHeader } = useSelector(
    (state) => state.attendee
  )
  const columns = useMemo(
    () =>
      attendeeCSVTableHeader.map((project, idx) => {
        return {
          id: idx,
          Header: project.Header,
          accessor: project.accessor,
          isSort: true,
          Cell: ({ row }) => {
            return <span>{row.original[project.accessor]}</span>
          }
        }
      }),
    []
  )
  return (
    <div className="certificate-template review" style={{ padding: '0 20px' }}>
      <ReactTable
        columns={columns}
        data={attendeeCSVList}
        tableSize={attendeeCSVList.length}
        isShowPagination={false}
        title="Attendees"
        isSorted={true}
        cName="review-csv-table"
      />
    </div>
  )
}
