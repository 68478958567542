import React, { useState } from 'react'
import Header from './Header'
import MainLayout from './PageLayout'
import SubMenu from './SubMenu'
import CreateBtn from '../button/CreateBtn'
import Footer from './Footer'

export default function Layout({
  title,
  mainPage,
  page,
  children,
  isCreateBtn,
  open,
  noMenu
}) {
  const [currentUser, setCurrentUser] = useState(null)

  return (
    <div className="layout">
      <Header mainPage={mainPage} currentUser={{ currentUser, setCurrentUser }} />

      <MainLayout className="view-frame">
        <div className="title">
          <h1>{title}</h1>
          {isCreateBtn ? <CreateBtn open={open} /> : null}
        </div>
        {noMenu ? (
          <div className="main-block center">{children}</div>
        ) : (
          <div className="main-block">
            <SubMenu page={page} />
            <div className="right">{children}</div>
          </div>
        )}
      </MainLayout>
      <Footer />
    </div>
  )
}
