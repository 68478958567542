import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import ErrorSpan from './ErrorSpan'
import 'react-datepicker/dist/react-datepicker.css'
import RedBtn from '../button/RedBtn'
import WhiteBtn from '../button/WhiteBtn'
import { ClearIcon } from '../icons/icons'
import { createNewCompany, updateCompany } from '../../store/action/companies.action'

export default function CompanyCreateEditForm({ close, modaltype, setOpenModal }) {
  const { companyDetail } = useSelector((state) => state.companies)
  const [showNClear, setShowNClear] = useState(false)
  const [showEClear, setShowEClear] = useState(false)
  const [showLClear, setShowLClear] = useState(false)
  const [showFNClear, setShowFNClear] = useState(false)
  const [showLNClear, setShowLNClear] = useState(false)
  const dispatch = useDispatch()

  const {
    register,
    watch,
    handleSubmit,
    resetField,
    reset,
    getValues,
    formState: { errors }
  } = useForm({ defaultValues: { ...companyDetail }, mode: 'onTouched' })
  const watchAllFields = watch()
  useEffect(() => {
    reset({
      ...companyDetail
    })
  }, [companyDetail, reset])

  useEffect(() => {
    if (!watchAllFields.company_name) {
      setShowNClear(false)
    } else {
      setShowNClear(true)
    }
    if (!watchAllFields.location) {
      setShowLClear(false)
    } else {
      setShowLClear(true)
    }
    if (!watchAllFields.admin_email) {
      setShowEClear(false)
    } else {
      setShowEClear(true)
    }
    if (!watchAllFields.admin_first_name) {
      setShowFNClear(false)
    } else {
      setShowFNClear(true)
    }
    if (!watchAllFields.admin_last_name) {
      setShowLNClear(false)
    } else {
      setShowLNClear(true)
    }
  }, [watchAllFields])

  const clearFunc = (type) => {
    resetField('company_name')
    resetField('location')
    resetField('admin_email')
    resetField('admin_first_name')
    resetField('admin_last_name')
  }
  const onSubmit = (data) => {
    if (modaltype === 'edit') {
      dispatch(updateCompany(data.id, data))
      setOpenModal(false)
    } else {
      dispatch(createNewCompany(data))
      setOpenModal(false)
    }
  }
  const checkKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      const data = getValues()
      onSubmit(data)
    }
  }
  return (
    <form
      className="modal-form"
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e) => checkKeyDown(e)}
    >
      <div className="input-frame">
        <input
          placeholder="Company Name"
          {...register('company_name', {
            required: true
          })}
        />
        {showNClear && (
          <div className="icon-frame">
            <ClearIcon size="20" clear={() => clearFunc('company_name')} />
          </div>
        )}
      </div>
      {errors.company_name ? <ErrorSpan>This field is required!</ErrorSpan> : ''}
      <div className="input-frame">
        <input
          placeholder="Company Location (City Name)"
          {...register('location', {
            required: true
          })}
        />
        {showLClear && (
          <div className="icon-frame">
            <ClearIcon size="20" clear={() => clearFunc('location')} />
          </div>
        )}
      </div>
      {errors.location ? <ErrorSpan>This field is required!</ErrorSpan> : ''}
      <div className="input-frame">
        <input
          placeholder="Company Contact First Name"
          {...register('admin_first_name', {
            required: true
          })}
        />
        {showFNClear && (
          <div className="icon-frame">
            <ClearIcon size="20" clear={() => clearFunc('admin_first_name')} />
          </div>
        )}
      </div>
      {errors.admin_first_name ? <ErrorSpan>This field is required!</ErrorSpan> : ''}
      <div className="input-frame">
        <input
          placeholder="Company Contact Last Name"
          {...register('admin_last_name', {
            required: true
          })}
        />
        {showLNClear && (
          <div className="icon-frame">
            <ClearIcon size="20" clear={() => clearFunc('admin_last_name')} />
          </div>
        )}
      </div>
      {errors.admin_last_name ? <ErrorSpan>This field is required!</ErrorSpan> : ''}
      <div className="input-frame">
        <input
          placeholder="Company Contact Email"
          {...register('admin_email', {
            required: true
          })}
        />
        {showEClear && (
          <div className="icon-frame">
            <ClearIcon size="20" clear={() => clearFunc('admin_email')} />
          </div>
        )}
      </div>
      {errors.admin_email ? <ErrorSpan>This field is required!</ErrorSpan> : ''}
      <div className="btn-frame">
        <WhiteBtn
          text="Cancel"
          type="cancel"
          close={close}
          width={200}
          height={50}
          marginR={50}
        />
        <RedBtn text="submit" type="submit" width={200} height={50} />
      </div>
    </form>
  )
}
