import React from "react";
import { CreateIcon } from "../icons/icons";

export default function CreateBtn({ open }) {
  return (
    <button className="create-btn">
      <CreateIcon size="25" open={open} />
    </button>
  );
}
