import React from "react";
import { Link } from "react-router-dom";

export default function WhiteBtn({
  text,
  close,
  type,
  width,
  height,
  marginR,
  marginTop,
  path,
}) {
  return path ? (
    <Link to={path}>
      <button
        className="white-btn"
        type={type}
        onClick={close}
        style={{
          width: `${width}px`,
          height: `${height}px`,
          marginRight: `${marginR}px`,
          marginTop: `${marginTop}px`,
        }}
      >
        {text}
      </button>
    </Link>
  ) : (
    <button
      className="white-btn"
      type={type}
      onClick={close}
      style={{
        width: `${width}px`,
        height: `${height}px`,
        marginRight: `${marginR}px`,
      }}
    >
      {text}
    </button>
  );
}
